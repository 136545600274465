import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { useInViewport } from 'ahooks';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// 组件
import NavMenu from '@/components/Nav1';
import FooterNav from '@/components/FooterNav';
import AboutCorp from '@/components/AboutCorp';


import './index.scss';
// 文件
import adorn from '@/assets/line_4.png';
import adorn1 from '@/assets/line_6.png';
// import aboutSniff from '@/assets/aboutSniff.png';
import aboutSniff from '@/assets/aboutSniff.jpg';
import playBtn from '@/assets/play.png';
import pausedBtn from '@/assets/paused.png';


// paused
import { useRect } from '../../components/hooks';
import classNames from 'classnames';


function About() {
  // const { lang } = useParams();
  // const { t } = useTranslation();
  const { t } = useTranslation();
  const { lang: langInPath } = useParams();

  const view = useRef()
  function scrollToNext() {
    view.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
  }

  const videoRef = useRef();
  const [isPlay, setIsPlay] = useState(false);

  const [showPosterImg, setShowPosterImg] = useState(true);

  const [isMouseMoving, setIsMouseMoving] = useState(false);

  const onMouseEnterRef = useRef();

  const onMouseEnter = () => {
    onMouseEnterRef.current = true
  }

  const onMouseLeave = () => {
    onMouseEnterRef.current = false
  }

  useEffect(() => {

    let mouseMoveTimeout = null;

    const handleMouseMove = () => {
      if (onMouseEnterRef.current) {
        clearTimeout(mouseMoveTimeout);
        setIsMouseMoving(false);
        return
      }
      setIsMouseMoving(true);
      clearTimeout(mouseMoveTimeout);
      mouseMoveTimeout = setTimeout(() => {
        setIsMouseMoving(false);
      }, 1000);
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      clearTimeout(mouseMoveTimeout);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);
  
  const onVideoPlay = () => {
    setShowPosterImg(false)
    setIsPlay(true);
    if (!isPlay) {
      videoRef.current.play();
    }

    // if (showPosterImg) {
    //   setShowPosterImg(false)
    // }
    // if (isPlay) {
    //   videoRef.current.ended();
    //   setShowPosterImg(true)
    //   setIsPlay(false);
    // } else {
    //   videoRef.current.play();
    // }
  }

  const onVideoPaused = () => {
    const video = videoRef.current;
    video.pause();
    setIsPlay(false);
  }

  const handleVideoEnded = () => {
    setShowPosterImg(true)
    setIsPlay(false);
  }

  const [view01Rect, view01Ref] = useRect();
  const [view02Rect, view02Ref] = useRect();

  const navThemes = {
    'sniff-nav-dark': view01Rect.y > (-view01Rect.height + 64),
    'sniff-nav-light': view02Rect.y < 0 && view02Rect.y > -view02Rect.height
  }

  const consultationRef = useRef(null)
  const [consultationInViewport] = useInViewport(consultationRef)

  return (
    <div>
      <div className="page">
        {/* 介绍 视频 */}
        <div className="about-group" ref={view01Ref}>
          <video style={{ opacity: showPosterImg ? 0 : 1 }} ref={videoRef} className="video-wrap1" onEnded={handleVideoEnded} autoPlay={false} preload="true">
            <source autoPlay={true} preload="true" src="https://static-s.theckb.com/sniffGroup/videos/about.mp4" type="video/mp4" />
          </video>
          <img className="cover-img" style={{ display: showPosterImg ? 'block': 'none' }} src={aboutSniff} alt='' />
          <div className="screen-box1">
            <NavMenu lang={langInPath} classLocation={'.about-group'} themes={navThemes} />
            <div id='AboutGroup' className="about-wrap">
              {showPosterImg && <div className="about-block flex-row">
                  <h1 className="about-title">
                    <span>{t('100秒了解直行便')}</span>
                    <img
                      className="about-adorn"
                      src={adorn}
                      alt=''
                    />
                  </h1>
                  <span className="about-txt">
                    {t('SNIFF GROUP 运营的THE CKB是一家什么样的公司？我们将在视频中详细讲解THE CKB提供的进口业务相关解决方案。')}
                  </span>
                  {/* <img className='play-btn' onClick={onVideoPlay} src={playBtn} alt='' /> */}
                </div>
              }
              {
                isPlay ? <img className={classNames(['play-btn', { 'fade-in': !isMouseMoving, 'fade-out': isMouseMoving }])} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onVideoPaused} src={pausedBtn} alt='' /> : 
                <img className={classNames(['play-btn'])} onClick={onVideoPlay} src={playBtn} alt='' />
              }
            </div>
            {/* 下滑滚动 */}
              <div onClick={scrollToNext} className="slide-browse">
              <i className="iconfont slide-icon">&#xe6a9;</i>
              <i className="iconfont slide-icon1">&#xe6a9;</i>
              <span className="browse-more">{t('滑动浏览更多')}</span>
            </div>
          </div>
        </div>
        {/* 了解直行便 */}
        <div ref={view}></div>
        <div id='SlideGroup'>
          <AboutCorp ref={view02Ref} />
        </div>
        
        {/* 直行便业务咨询 */}
        <div id='BusinessGroup' className="business-group">
          <div className='business-wrap'>
            {/* <h1 className="consultation-title"> */}
            <h1 ref={consultationRef} className={classNames(['consultation-title'], { 'animate': consultationInViewport })}>

              <span>{t('直行便业务咨询')}</span>
              <img
                className="business-adorn"
                src={adorn1}
                alt=''
              />
              </h1>
            <div className="consultation-wrap flex-row justify-between">
              <div className="phone-section">
                <div className="contact-section">
                  <div className="phone-main flex-row justify-between">
                    <i className="iconfont mini-icon">&#xe6a0;</i>
                    <span className="phone-txt">{t('电话号码')}</span>
                  </div>
                  <span className="phone-num">03-4446-7313</span>
                  <div className="phone-split" />
                  <span className="time-text">{t('工作时间')}: 10:00~13:00,14:15~19:00</span>
                  <span className="time-text">{t('北京')}（GMT+8）</span>
                  <a href='tel:03-4446-7313'>
                  <div className="work-contact-btn">
                    <span className="work-btn-txt">{t('立即咨询')}</span>
                  </div>
                  </a>
                </div>
              </div>
              <div className="email-section">
                <div className="contact-section">
                  <div className="email-top flex-row justify-between">
                    <i className="iconfont email-icon">&#xe69f;</i>
                    <span className="email-txt">{t('电子邮件')}</span>
                  </div>
                  <span className="email-url">info&#64;theckb.com</span>
                  <div className="email-split" />
                  <span className="time-text" />
                  <span className="time-text" />
                  <a href='mailto:info@theckb.com'>
                  <div className="work-contact-btn">
                    <span className="work-btn-txt">{t('立即咨询')}</span>
                  </div>
                  </a>
                </div>
              </div>
              <div className="line-section">
                <div className='contact-section'>
                  <div className="line-wrap flex-row justify-between">
                    <i className="iconfont line-icon">&#xe69e;</i>
                    <span className="line-text">LINE</span>
                  </div>
                  <span className="line-title">{t('THE 直行便')}</span>
                  <div className="line-split" />
                  <span className="time-text">{t('工作时间')}: 10:00~13:00,14:15~19:00</span>
                  <span className="time-text">{t('北京')}（GMT+8）</span>
                  <a href='https://line.me/R/ti/p/@962puoaf?oat_content=url'>
                    <div className="work-contact-btn">
                      <span className="work-btn-txt">{t('立即咨询')}</span>
                    </div>
                  </a>
                </div>

              </div>
            </div>
          </div>

        </div>
        {/* 底部菜单 */}
        <FooterNav />
      </div>
    </div>
  );
}

export default About;
