import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { Navigation, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import Nav from '../components/Nav1';
import './index.scss';
import xianggang1 from '@/assets/xianggang1.png'


function OfficeXG() {
  const { t } = useTranslation();
  const { lang: langInPath } = useParams();
  
  return (
    <div id='XGTop' className="office-page flex-col">
        {/* 菜单栏 */}
      <div className="news-menu-group">
        <Nav lang={langInPath} />
      </div>
      <div className="office-wrapper">
        <h1 className="office-title">{t('选择你喜欢的城市办公')}&nbsp;—&nbsp;{t('香港')}</h1>
        <div className="office-text-wrap">
          <div className="office-paragraph">
            {t('中国香港是一座高度繁荣的自由港和国际大都市，重要的国际贸易、航运中心和国际创新科技中心。')}
          </div>
          <div className="office-paragraph">
            {t('中国香港是全球少有之“自由港城”，地理位置优越，处于亚洲的中心，交通畅通，货物进出便利，在跨境电商行业的发展上具有得天独厚的优势。')}
          </div>
          <div className="office-paragraph">
            {t('同时香港还具有境内备货方便，外汇收款自由，税务政策便利等多种优势，是发展跨境事业最合适的港湾！')}
          </div>
        </div>
        <div className="office-group">
          <Swiper
            navigation={true}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            modules={[Navigation, Autoplay]}
            className="row-wrap mySwiper"
          >
            <SwiperSlide style={{width: '100%', display: 'flex', justifyContent: 'center', height: 600}}>
              <img className="block_1" src={xianggang1} alt='' />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>


    </div>
  );
}

export default OfficeXG;
