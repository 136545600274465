import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { Navigation, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import Nav from '../components/Nav1';
import './index.scss';
import hanguo1 from '@/assets/hanguo1.png'

function OfficeHG() {
  const { t } = useTranslation();
  const { lang: langInPath } = useParams();
  
  return (
    <div id='HGTop' className="office-page flex-col">
        {/* 菜单栏 */}
      <div className="news-menu-group">
        <Nav lang={langInPath} />
      </div>
      <div className="office-wrapper">
        <h1 className="office-title">{t('选择你喜欢的城市办公')}&nbsp;—&nbsp;{t('韩国金浦')}</h1>
        <div className="office-text-wrap">
          <div className="office-paragraph">
            {t('韩国拥有良好的互联网基础设施，为电子商务的发展打下坚实基础。韩国电子商务行业市场规模位居世界前列，是重要的消费市场。')}
          </div>
          <div className="office-paragraph">
            {t('SNIFF韩国分公司位于京畿道金浦市金浦汉江9路75号街190,7楼715号-V3(九来洞,Inner Mass汉江)，主要负责韩国地区市场开拓，招商等工作。韩国分公司是SNIFF拓展韩国市场，增加海外市场厚度的重要力量！')}
          </div>
        </div>
        <div className="office-group">
          <Swiper
            navigation={true}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            modules={[Navigation, Autoplay]}
            className="row-wrap mySwiper"
          >
            <SwiperSlide style={{width: '100%', display: 'flex', justifyContent: 'center', height: 600}}>
              <img className="block_1" src={hanguo1} alt='' />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>


    </div>
  );
}

export default OfficeHG;
