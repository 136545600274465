import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from "react-router-dom";
import { Link } from 'react-router-dom'
import { useInViewport } from 'ahooks';

import Nav from '@/components/Nav1';
import FooterNav from '@/components/FooterNav';
import './index.scss';
import { useRequest } from 'ahooks';
import axios from 'axios';
import moment from 'moment';
import classNames from 'classnames';

// import 'rc-pagination/assets/index.css';
import dateSlice from '@/assets/dateSlice.png';
import descSlice from '@/assets/descSlice.png';

import { ConfigProvider, Pagination } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import enUS from 'antd/es/locale/en_US';

function getArtcles() {
  return axios({ url: `https://static-s.theckb.com/sniffGroup/article/articleList.prod.json?_time=${new Date().getTime()}`})
}

function News() {
  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { lang: langInPath } = useParams();

  const articleClassification = searchParams.get('classification') || '企业合作'
  const currentPageNo = Number(searchParams.get('pageNo')) || 1
  const pageSize = Number(searchParams.get('pageSize')) || 10

  const [artcles, setArtcles] = useState([]);
  const [artclesLength, setArtclesLength] = useState([]);
  // const articleStation = langMapArticleStation[langInPath];
  const { run, loading } = useRequest(getArtcles, {
    onSuccess(data) {
      const list = data.data
      .filter(({ title, enTitle }) => {
        if(langInPath === 'en' && enTitle) {
          return true
        }
        if (langInPath === 'cn' && title) {
          return true
        }
        return false;
      })
      .filter(({ classification }) => classification === articleClassification)
      setArtclesLength(list.length)

      const displayRangeStart = (currentPageNo * pageSize) - pageSize
      const displayRangeEnd = currentPageNo * pageSize - 1

      const _artcles = list.filter((_, index) => index >= displayRangeStart && index <= displayRangeEnd);

      setArtcles(_artcles);
      console.log('_artcles', _artcles)
    }
  });

  useEffect(() => {
    run()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, articleClassification, currentPageNo, pageSize])

  const onShowSizeChange = (current, pageSize) => {
    console.log(current);
    console.log(pageSize);
  };

  const newsHeartRef = useRef(null)
  const [newsHeartInViewport] = useInViewport(newsHeartRef)
  // const [moveUp, setMoveUp] = useState(false);
  // const [moveUpAnim, setMoveUpAnim] = useState(true);

  const locale = langInPath === 'cn' ? zhCN : enUS;

  
  
  // const timerIdRef = useRef();
  // useEffect(() => {
  //   setMoveUp(false);
  //   clearTimeout(timerIdRef.current);
  //   setMoveUp(true);
  //   setMoveUpAnim(true);
  //   timerIdRef.current = setTimeout(() => {
  //     setMoveUp(false);
  //     setMoveUpAnim(false);
  //   }, 1000)
  // }, [searchParams])

  return (
    <>
      <div  className="news-page">
        <div className="news-menu-group">
          <Nav lang={langInPath} />
        </div>
        {/* tab切换 */}
        <div className="tabs" id='ArticleContent'>
          <div className="tab-group">
              <ul className="tab-list">
                  <li onClick={() => { setSearchParams({ classification: '企业合作' }) }} id='Cooperate' className={classNames({"current": articleClassification === '企业合作'})}>{t('企业合作')}</li>
                  <li onClick={() => { setSearchParams({ classification: '产品动态' }) }} id='Dynamic' className={classNames({"current": articleClassification === '产品动态'})}>{t('产品动态')}</li>
                  <li onClick={() => { setSearchParams({ classification: '公告' }) }} id='Bulletin' className={classNames({"current": articleClassification === '公告'})}>{t('公告')}</li>
              </ul>
          </div>
          <div ref={newsHeartRef} className={classNames(['tab-con'], { 'move-up': newsHeartInViewport && !loading })}>
            {artcles.map((article) => (
              <div className="item" key={article.formInstId}>
                <div className="news-date-wrap">
                  <div className="news-date">
                    <span className="news-year">{moment(article.date).format('YYYY')}</span>
                    <img className="news-slice" src={dateSlice} alt='' />
                    <span className="news-time">{moment(article.date).format('MM')}.{moment(article.date).format('DD')}</span>
                  </div>
                  <img className="news-line" src={descSlice} alt='' />
                </div>
                <div className="news-section">
                  <span className="news-title">{langInPath === 'en' ? article.enTitle : article.title}</span>
                  <p className="news-blurb">
                    {langInPath === 'en' ? article.enOverview : article.overview}
                  </p>
                  <Link to={`/${langInPath}/NewsDetails/${(article.no)}`}>
                    <span className="news-btn-select flex-row">
                      <span className="news-btn-txt">{t('查看更多')}</span>
                      <i className="iconfont news-btn-icon">&#xe6a2;</i>
                    </span>
                  </Link>

                </div>
                <img className="news-image" src={langInPath === 'en' ? article.enImgUrl : article.imgUrl} alt='' />
              </div>
            ))}
            <div className='paging-wrap'>
              <ConfigProvider locale={locale}>
                <Pagination
                  total={artclesLength}
                  current={currentPageNo}
                  showSizeChanger={true}
                  showQuickJumper={true}
                  onShowSizeChange={onShowSizeChange}
                  showTotal={(total) => `${t('共')} ${total} ${t('条')}`}
                  defaultCurrent={currentPageNo}
                  pageSize={pageSize}
                  defaultPageSize={pageSize}
                  onChange={(current, pageSize) => {
                    setSearchParams({ classification: articleClassification, pageNo: current, pageSize })
                  }}
                />
              </ConfigProvider>
            </div>

          </div>
        </div>

        {/* 底部菜单 */}
        <FooterNav />
      </div>
    </>
  );
}

export default News;
