import { useEffect, useRef, useState } from 'react';

export const useRect = () => {
  const ref = useRef();
  const [myRect, setRect] = useState({});

  useEffect(() => {
    const handleScroll = () => {
      const box = ref.current;
      setRect(box.getBoundingClientRect())
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  
  }, [])

  const set = () => setRect(ref && ref.current ? ref.current.getBoundingClientRect() : {});

  useEffect(() => {
    set();
    window.addEventListener('resize', set);
    return () => window.removeEventListener('resize', set);
  }, []);

  return [myRect, ref];
};
