import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import News from './pages/News';
import JoinUs from './pages/JoinUs';
import NewsDetails from './pages/NewsDetails';
import OfficeHZ from './pages/OfficeCity';
import OfficeDJ from './pages/OfficeDJ';
import OfficeDL from './pages/OfficeDL';
import OfficeGZ from './pages/OfficeGZ';
import OfficeXG from './pages/OfficeXG';
import OfficeHG from './pages/OfficeHG';
import { useEffect } from 'react';
import { ConfigProvider } from 'antd';
import i18n from './i18n';

function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Outlet />,
      children: [
        {
          path: '/:lang',
          element: <Outlet />,
          children: [
            {
              path: 'Home', Component: Home
            },
            {
              path: 'About', Component: About
            },
            {
              path: 'News', Component: News
            },
            {
              path:'NewsDetails/:no', Component: NewsDetails
            },
            {
              path: 'JoinUs', Component: JoinUs
            },
            {
              path: 'OfficeHZ', Component: OfficeHZ
            },
            {
              path: 'OfficeDJ', Component: OfficeDJ
            },
            {
              path: 'OfficeDL', Component: OfficeDL
            },
            {
              path: 'OfficeGZ', Component: OfficeGZ
            },
            {
              path: 'OfficeXG', Component: OfficeXG
            },
            {
              path: 'OfficeHG', Component: OfficeHG
            }
          ]
        }
      ]
    }
  ], {
    future: { v7_startTransition: true }
  })

  router.subscribe((state) => {
    detectLangInLocation(state.location);
    // console.log('state.location.hash', state.location)
    if (/#[A-Z]+/.test(state.location.hash)) {
      setTimeout(() => {
        const link = document.querySelector(state.location.hash);
        if (link) {
          link.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
        }
      }, 1)
    }
  })

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    detectLangInLocation(location);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  function detectLangInLocation(location) {
    if (location) {
      // 路径不是cn,en开头
      if ( !/\/[cn|en]/.test(location.pathname) ) {
        let language = localStorage.getItem('language')
        if (!language) {
          if (/-[cn]/i.test(navigator.language)) {
            language = 'cn'
          }
          // en-US
          if (/[en]-/i.test(navigator.language)) {
            language = 'en'
          }
        }
        i18n.changeLanguage(language)
        router.navigate(`/${language}/Home#Top1`)
      }
    }
  }

  return (
    <ConfigProvider 
      theme={{
        components: {
          Tabs: {
            algorithm: true,
            colorPrimary: '#D8A237'
          },
          Pagination: {
            colorBgContainer: 'transparent',
            algorithm: true,
            colorPrimary: '#D8A237',
            borderRadius: 2,
          },
          Select: {
            borderRadius: 2,
            selectorBg: 'transparent',
            algorithm: true,
            colorPrimary: '#D8A237',
          }
        }
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  );
}

export default App;
