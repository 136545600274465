import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { Navigation, Autoplay } from 'swiper/modules';
import { Swiper, useSwiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import Nav from '../components/Nav1';
import './index.scss';
import dongjing1 from '@/assets/dongjing1.png'
import dongjing2 from '@/assets/dongjing2.png'
import dongjing3 from '@/assets/dongjing3.png'
import left from '@/assets/swipe_left.png'
import right from '@/assets/swipe_right.png'

const SwiperButtonNext = ({ children }) => {
  const swiper = useSwiper();
  return (
    <span
      slot="container-start"
      onClick={() => swiper.slideNext()}
      className="swiper-button-prev"
      style={{
        right: "var(--swiper-navigation-sides-offset, 10px)",
        left: "auto",
      }}
    >
      <img className='swiper-btn' src={right} alt='' />
    </span>
  );
};

const SwiperButtonPrev = ({ children }) => {
  const swiper = useSwiper();
  return (
    <span
      onClick={() => swiper.slidePrev()}
      className="swiper-button-next"
      style={{
        left: "var(--swiper-navigation-sides-offset, 10px)",
        right: "auto",
      }}
      slot="container-start"
    >
      <img className='swiper-btn' src={left} alt='' />
  </span>
  );
};

function OfficeDJ() {
  const { t } = useTranslation();
  const { lang: langInPath } = useParams();
  
  return (
    <div id='DJTop' className="office-page flex-col">
        {/* 菜单栏 */}
      <div className="news-menu-group">
        <Nav lang={langInPath} />
      </div>
      <div className="office-wrapper">
        <h1 className="office-title">{t('选择你喜欢的城市办公')}&nbsp;—&nbsp;{t('东京')}</h1>
        <div className="office-text-wrap">
          <div className="office-paragraph">
            {t('东京分公司是Sniff的日本办公中心，员工都是更熟悉当地文化的日本籍成员，主要负责与日方各大公司进行合作洽谈，商务对接等工作。')}
            </div>
          <div className="office-paragraph">
            {t('日籍员工可以更顺畅与客户进行交流和沟通，完成大客户的拜访和维护，帮助日本项目顺利推进。对于当地文化环境的了解决定来他们能掌握迅速识别和判断市场动向，掌握第一手资料。日本分公司能迅速洞察当地人们的兴趣变化，并依此来进行日本市场的宣传推广工作。欢迎加入Sniff Group，与世界各地的同事一起交流！')}
          </div>
        </div>
        <div className="office-group">
          <Swiper 
            navigation={false}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            modules={[Navigation, Autoplay]}
            className="row-wrap mySwiper"
          >
            <SwiperButtonPrev />
            <SwiperButtonNext />
            <SwiperSlide style={{width: '100%', display: 'flex', justifyContent: 'center', height: 600}}>
              <img className="block_1" src={dongjing1} alt='' />
            </SwiperSlide>
            <SwiperSlide style={{width: '100%', display: 'flex', justifyContent: 'center', height: 600}}>
              <img className="block_1" src={dongjing2} alt='' />
            </SwiperSlide>
            <SwiperSlide style={{width: '100%', display: 'flex', justifyContent: 'center', height: 600}}>
              <img className="block_1" src={dongjing3} alt='' />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>


    </div>
  );
}

export default OfficeDJ;
