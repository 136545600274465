import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import { Navigation, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import Nav from '../components/Nav1';
import './index.scss';
import guangzhou1 from '@/assets/guangzhou1.png'

function OfficeGZ() {
  const { t } = useTranslation();
  const { lang: langInPath } = useParams();
  
  return (
    <div id='GZTop' className="office-page flex-col">
        {/* 菜单栏 */}
      <div className="news-menu-group">
        <Nav lang={langInPath} />
      </div>
      <div className="office-wrapper">
        <h1 className="office-title">{t('选择你喜欢的城市办公')}&nbsp;—&nbsp;{t('广州')}</h1>
        <div className="office-text-wrap">
          <div className="office-paragraph">
            {t('广州分公司主要负责华南地区供应商的海外供货，并提供质检，仓储，物流等服务。')}
          </div>
          <div className="office-paragraph">
            {t('广州位于珠江口，毗邻香港，地理位置优越，是中国最大的对外贸易口岸之一，具有地理位置优越、交通便利、产品资源丰富等优势。')}
          </div>
          <div className="office-paragraph">
            {t('广州不仅拥有完善的海空陆交通网络，可以方便地将商品运往世界各地；同时还具备丰富的产品资源，产品种类丰富多样，能满足全球各地人们的生产生活需求。因此，广州也成为SNIFF发展跨境事业的新基地！')}
          </div>
        </div>
        <div className="office-group">
          <Swiper
            navigation={true}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            modules={[Navigation, Autoplay]}
            className="row-wrap mySwiper"
          >
            <SwiperSlide style={{width: '100%', display: 'flex', justifyContent: 'center', height: 600}}>
              <img className="block_1" src={guangzhou1} alt='' />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>


    </div>
  );
}

export default OfficeGZ;
