import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

import greyLogo from '../assets/sniff_grey.png';
import { Link, useNavigate } from 'react-router-dom'

const FooterNav = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { lang: langInPath } = useParams();
  return (
    <div className="menu-group flex-col">
      <div className="menu-wrap flex-row">
        <img
          className="menu-logo"
          src={greyLogo}
          alt=''
          onClick={() => { navigate('/') }}
        />
        <div className='menu-list'>
          <div className="menu-col">
            <span className="menu-head">{t('了解直行便')}</span>
            <div className="group-wrap">
              <span className="menu-text">
                <Link to={`/${langInPath}/About#SlideGroup`}>{t('直行便优势')}</Link>
                </span>
              <span className="menu-text">
                <Link to={`/${langInPath}/About#BusinessGroup`}>{t('业务咨询')}</Link>
              </span>
            </div>
          </div>
          <div className="menu-col">
            <span className="menu-head">{t('最新资讯')}</span>
            <div className="group-wrap">
              <span className="menu-text">
                <Link to={`/${langInPath}/News?classification=企业合作#Cooperate`}>{t('企业合作')}</Link>
              </span>
              <span className="menu-text">
                <Link to={`/${langInPath}/News?classification=产品动态#Dynamic`}>{t('产品动态')}</Link>
              </span>
              <span className="menu-text">
                <Link to={`/${langInPath}/News?classification=公告#Bulletin`}>{t('公告')}</Link>
              </span>
            </div>
          </div>
          <div className="menu-col">
            <span className="menu-head">{t('加入我们')}</span>
            <div className="group-wrap">
              <span className="menu-text">
                <Link to={`/${langInPath}/JoinUs#Possibility`}>{t('工作机会')}</Link>
              </span>
              <span className="menu-text">
                <Link to={`/${langInPath}/JoinUs#Welfare`}>{t('贴心福利')}</Link>
              </span>
              <span className="menu-text">
                <Link to={`/${langInPath}/JoinUs#OfficeCity`}>{t('办公城市')}</Link>
              </span>
              <span className="menu-text">
                <Link to={`/${langInPath}/JoinUs#JoinSniff`}>{t('走进SNIFF')}</Link>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="menu-split flex-col" />
      <div className="bottom-contact flex-row">
        <div className="right">
          <span className="right-icon">©︎</span>
          {/* © 2023 sniffgroup  浙ICP备16032890号-10  */}
          <span className="right-txt">&nbsp;2023 sniffgroup  <a href='https://beian.miit.gov.cn/#/Integrated/index' target="_blank" rel="noreferrer" >浙ICP备16032890号-10</a></span>
        </div>
        <div className='contact-way'>
          <span className="contact-us">{t('联系我们')}</span>
          <div className="way-wrap">
            <a target='_blank' href='https://www.instagram.com/theckb_official/' rel="noreferrer">
              <i className="iconfont way-icon">&#xe69c;</i>
            </a>
            <a target='_blank' href='https://twitter.com/Theckb_official' rel="noreferrer">
              <i className="iconfont way-icon">&#xe69b;</i>
            </a>
            <a target='_blank' href='https://www.facebook.com/people/THE直 ⾏行行便便_jp/100088353218048/' rel="noreferrer">
              <i className="iconfont way-icon">&#xe699;</i>
            </a>
            <a target='_blank' href='https://page.line.me/962puoaf?oat_content=url&openQrModal=true' rel="noreferrer">
              <i className="iconfont way-icon">&#xe698;</i>
            </a>
          </div>

        </div>

      </div>
    </div>
  )
}

export default FooterNav
