import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from "react-router-dom";
import { Link } from 'react-router-dom'
import { Tabs } from 'antd';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { HashNavigation } from 'swiper/modules';

import Nav from '@/components/Nav1';
import FooterNav from '@/components/FooterNav';

import officeAdorn from '@/assets/line_3.png';
import idealAdorn from '@/assets/line_7.png';
import heartAdorn from '@/assets/line_9.png';
import welfareAdorn from '@/assets/line_8.png';

import xiaoxin from '@/assets/xiaoxin.png';
import aixin from '@/assets/aixin.jpg';
import jiankang from '@/assets/jiankang.png';
import kaifang from '@/assets/kaifang.png';
import yuanjing from '@/assets/yuanjing.png';
import shiming from '@/assets/shiming.png';
import wenhua from '@/assets/wenhua.png';
import nengli from '@/assets/nengli.png';
import jiazhiguan from '@/assets/jiazhiguan.png';

import s from '@/assets/s.png';
import n from '@/assets/n.png';
import i from '@/assets/i.png';
import f1 from '@/assets/f1.png';
import f2 from '@/assets/f2.png';

import './index.scss';
import { useInViewport } from 'ahooks';
import classNames from 'classnames';

import 'animate.css';
import { useRect } from '../../components/hooks';

function JoinUs() {
  const { t } = useTranslation();
  const location = useLocation();
  const { lang: langInPath } = useParams();
  const items = [
    {
      label: <span>{t('企业愿景')}</span>,
      key: '#companyGoal1',
      children: ''
    },
    {
      label: <span>{t('企业使命')}</span>,
      key: '#companyGoal2',
      children: ''
    },
    {
      label: <span>{t('企业文化')}</span>,
      key: '#companyGoal3',
      children: ''
    },
    {
      label: <span>{t('企业核心能力')}</span>,
      key: '#companyGoal4',
      children: ''
    },
    {
      label: <span>{t('企业核心价值观')}</span>,
      key: '#companyGoal5',
      children: ''
    },
  ]

  const view = useRef()
  function scrollToNext() {
    view.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
  }

  const loveHeartRef = useRef(null)
  const [loveHeartInViewport] = useInViewport(loveHeartRef)
  const [startLoveMaskAnimate, setStartLoveMaskAnimate] = useState(false);


  const filialPietyRef = useRef(null)
  const [filialPietyInViewport] = useInViewport(filialPietyRef)
  const [startPietyMaskAnimate, setStartPietyMaskAnimate] = useState(false);


  const healthRef = useRef(null)
  const [healthInViewport] = useInViewport(healthRef)
  const [startHealthMaskAnimate, setStartHealthMaskAnimate] = useState(false);

  
  const opennessRef = useRef(null)
  const [opennessInViewport] = useInViewport(opennessRef)
  const [startOpennessMaskAnimate, setStartOpennessMaskAnimate] = useState(false);

  
  const goal1 = useRef(null)
  const [goal1InViewport] = useInViewport(goal1)
  const [startgoal1MaskAnimate, setStartgoal1MaskAnimate] = useState(false);


  const goal2 = useRef(null)
  const [goal2InViewport] = useInViewport(goal2)
  const [startgoal2MaskAnimate, setStartgoal2MaskAnimate] = useState(false);


  const goal3 = useRef(null)
  const [goal3InViewport] = useInViewport(goal3)
  const [startgoal3MaskAnimate, setStartgoal3MaskAnimate] = useState(false);


  const goal4 = useRef(null)
  const [goal4InViewport] = useInViewport(goal4)
  const [startgoal4MaskAnimate, setStartgoal4MaskAnimate] = useState(false);


  const goal5 = useRef(null)
  const [goal5InViewport] = useInViewport(goal5)
  const [startgoal5MaskAnimate, setStartgoal5MaskAnimate] = useState(false);

  const officeCityRef = useRef(null)
  const [officeCityInViewport] = useInViewport(officeCityRef)

  const enteringRef = useRef(null)
  const [enteringInViewport] = useInViewport(enteringRef)
  
  const [view01Rect, view01Ref] = useRect();
  const [view02Rect, view02Ref] = useRect();

  const navThemes = {
    'sniff-nav-dark': view01Rect.y > (-view01Rect.height + 64),
    'sniff-nav-light': view02Rect.y < 0 && view02Rect.y > -view02Rect.height
  }

  return (
    <>
      <div className="page">
        {/* 加入我们 */}
        <div id='Possibility' className="joinUs-group" ref={view01Ref}>
          <div className="cover-layer">
            <div className="top-group">
              <Nav lang={langInPath} classLocation={'.joinUs-group'} themes={navThemes} />
            </div>
            <div className="goal-box">
              <h1 className="ideal-txt">
                <span>{t('我们立志成为全球优秀雇佣公司')}</span>
                <img
                  className="ideal-adorn"
                  src={idealAdorn}
                  alt=''
                />
              </h1>
              <p className="goal-txt">{t('我们的目标：消除办公场地、时间、距离限制、享受工作 与生活平衡的乐趣')}</p>
              <Link to={'https://s.xinrenxinshi.com/recruitGate/list#/ey=0ebdb0058d4a716b8f15cca6cc385e4089c663'}>
                <div className="goal-btn">
                    <span className="btn-txt">{t('立即投递简历')}</span>
                    <i className="iconfont join-btn-icon">&#xe6a3;</i>
                </div>
              </Link>
            </div>
          </div>

          <div onClick={scrollToNext} className="slide-browse">
            <i className="iconfont slide-icon">&#xe6a9;</i>
            <i className="iconfont slide-icon1">&#xe6a9;</i>
            <span className="browse-more">{t('滑动浏览更多')}</span>
          </div>
        </div>
        {/* 福利 */}
        <div ref={view02Ref}>
          <div id='Welfare' ref={view} className="welfare-one">
            <h1 className={classNames(['welfare-title'], { 'animate': loveHeartInViewport })}>
              <span className="title-txt">{t('SNIFF贴心福利')}</span>
              <img className="welfare-Adorn" src={welfareAdorn} alt='' />
            </h1>
            <div className="heart">
              <div className='heart-section'>
                <div className={classNames(['left'], { 'animate': loveHeartInViewport })}>
                  <h1 className="keyword">
                    <span>{t('爱心')}</span>
                    <img className="adorn" src={heartAdorn} alt='' />
                  </h1>
                  <div className="content">
                    <p className="brief">{t('定期发放宠物基金，让铲屎官们轻松陪伴爱宠')}</p>
                    <span className="details">
                      {t('Sniff Group 是一个温暖有爱的公司，我们深知宠物是铲屎官们非常重要的朋友和家人。为了让员工能更轻松的享受爱宠的陪伴，帮助员工照顾宠物，公司提供了专属的宠物福利——“宠物基金”。宠物基金每月按时发放，公司用实际行动为萌宠加餐啦！')}
                    </span>
                  </div>
                </div>
                <div className={classNames(['right'], { 'mask-animate': startLoveMaskAnimate })}>
                    <div className="mask-img">
                      <img className="figure" src={aixin} alt='' />
                      <div ref={loveHeartRef} className="image-trigger-show"></div>
                    </div>
                </div>
                <div className="jump-words">
                    <img className={classNames(["aixin"], loveHeartInViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startLoveMaskAnimate })} style={{ left: '0rem', bottom: '8rem'}} src={n} alt='' />
                    <img className={classNames(["aixin"], loveHeartInViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startLoveMaskAnimate })} style={{ left: '8rem', bottom: '1rem'}} src={i} alt='' />
                    <img className={classNames(["aixin"], loveHeartInViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startLoveMaskAnimate })} style={{ left: '18rem', bottom: '1rem'}} src={f1} alt='' />
                    <img onAnimationEnd={() => setStartLoveMaskAnimate(true)} className={classNames(["aixin"], loveHeartInViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startLoveMaskAnimate })} style={{ right: '1rem', bottom: '1rem'}} src={f2} alt='' />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="welfare-two">
          <div className='filial-piety-section'>
            <div className={classNames(['right'], { 'mask-animate': startPietyMaskAnimate })}>
              <div className="mask-img">
                <img className="figure" src={xiaoxin} alt='' />
                <div ref={filialPietyRef} className="image-trigger-show"></div>
              </div>
            </div>
            <div className="jump-words">
                <img className={classNames(["aixin"], filialPietyInViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startPietyMaskAnimate })} style={{ left: '0rem', bottom: '8rem'}} src={s} alt='' />
                <img className={classNames(["aixin"], filialPietyInViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startPietyMaskAnimate })} style={{ left: '8rem', bottom: '1rem'}} src={i} alt='' />
                <img className={classNames(["aixin"], filialPietyInViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startPietyMaskAnimate })} style={{ left: '18rem', bottom: '1rem'}} src={f1} alt='' />
                <img onAnimationEnd={() => setStartPietyMaskAnimate(true)} className={classNames(["aixin"], filialPietyInViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startPietyMaskAnimate })} style={{ right: '1rem', bottom: '1rem'}} src={f2} alt='' />
            </div>
            <div className={classNames(['left'], { 'animate': filialPietyInViewport })}>
              <h1 className="keyword">
                <span>{t('孝心')}</span>
                <img className="adorn" src={heartAdorn} alt='' />
              </h1>
              <div className="content">
                <p className="brief">{t('年末向员工父母发放慰问基金，感恩她们的付出')}</p>
                <span className="details">
                  {t('父母德高，子女良教。Sniff Group 在感念每一位员工为公司作出的奉献的同时，也感谢他们的父母为社会和公司培养了诸多优秀人才。对于父母的辛苦付出，Sniff 感念于心，感恩于行。为父母设立了感恩基金，每年直接向父母打款，以此来表达Sniff 集团对父母的尊敬和感谢。')}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="welfare-three">
          <div className='health-section'>
            <div className={classNames(['left'], { 'animate': healthInViewport })}>
              <h1 className="keyword">
                <span>{t('健康')}</span>
                <img className="adorn" src={heartAdorn} alt='' />
              </h1>
              <div className="content">
                <p className="brief">{t('定期员工体检，鼓励同学们锻炼健康的体魄')}</p>
                <span className="details">
                  {t('SNIFF非常注重员工的身心健康，健康的身体是优质工作和生活的前提保障。SNIFF鼓励同学们经常锻炼身体，塑造健康的工作生活习惯。并为全体员工提供年度员工体检，以此来保障员工的身体健康。同时还有旅游基金，年度假期等等福利，让员工有时间放松自己，陪伴家人，更好的平衡工作和生活。')}
                </span>
              </div>
            </div>
            <div className={classNames(['right'], { 'mask-animate': startHealthMaskAnimate })}>
              <div className="mask-img">
                <img className="figure" src={jiankang} alt='' />
                <div ref={healthRef} className="image-trigger-show"></div>
              </div>
            </div>
            <div className="jump-words">
                <img className={classNames(["aixin"], healthInViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startHealthMaskAnimate })} style={{ left: '0rem', bottom: '8rem'}} src={s} alt='' />
                <img className={classNames(["aixin"], healthInViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startHealthMaskAnimate })} style={{ left: '8rem', bottom: '1rem'}} src={n} alt='' />
                <img className={classNames(["aixin"], healthInViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startHealthMaskAnimate })} style={{ left: '18rem', bottom: '1rem'}} src={f1} alt='' />
                <img onAnimationEnd={() => setStartHealthMaskAnimate(true)} className={classNames(["aixin"], healthInViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startHealthMaskAnimate })} style={{ right: '1rem', bottom: '1rem'}} src={f2} alt='' />
            </div>
          </div>
        </div>
        <div className="welfare-four">
          <div className='open-section'>
            <div className={classNames(['right'], { 'mask-animate': startOpennessMaskAnimate })}>
              <div className="mask-img">
                <img className="figure" src={kaifang} alt='' />
                <div ref={opennessRef} className="image-trigger-show"></div>
              </div>
            </div>

            <div className="jump-words">
              <img className={classNames(["aixin"], opennessInViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startOpennessMaskAnimate })} style={{ left: '0rem', bottom: '8rem'}} src={s} alt='' />
              <img className={classNames(["aixin"], opennessInViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startOpennessMaskAnimate })} style={{ left: '8rem', bottom: '1rem'}} src={n} alt='' />
              <img className={classNames(["aixin"], opennessInViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startOpennessMaskAnimate })} style={{ left: '18rem', bottom: '1rem'}} src={i} alt='' />
              <img onAnimationEnd={() => setStartOpennessMaskAnimate(true)} className={classNames(["aixin"], opennessInViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startOpennessMaskAnimate })} style={{ right: '1rem', bottom: '1rem'}} src={f2} alt='' />
            </div>

            <div className={classNames(['left'], { 'animate': opennessInViewport })}>
              <h1 className="keyword">
                <span>{t('开放')}</span>
                <img className="adorn" src={heartAdorn} alt='' />
              </h1>
              <div className="content">
                <p className="brief">{t('定期语言交流学习，多国家同学碰撞思想火花')}</p>
                <span className="details">
                  {t('SNIFF是一个国际化的公司，拥有多元的文化交流背景，日、韩、泰等多个国家地区的员工一起上班，互相交流学习，共同进步。我们一直以来坚持开放包容的态度，鼓励各国员工积极交流，互相探讨，不断开阔彼此视野。还会定期开放语言交流学习，来帮助同学们了解不同国家的文化，让多国同学们碰撞出更璀璨的思想火花。')}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* 选择城市 */}
        <div id='OfficeCity' className="office-city">
          <h1 ref={officeCityRef} className={classNames(['office-title'], { 'animate': officeCityInViewport })}>
            <span>{t('选择你喜欢的城市办公')}</span>
            <img className="office-adorn" src={officeAdorn} alt='' />
          </h1>
          <div className="city-legend">

            <div className="list-items">
              <div class="background city-1"></div>
              <div className="info-wrap">
                <div className="info-content">
                  <div className="city-location">
                    <i className="iconfont city-icon">&#xe697;</i>
                    <span className="city-name">{t('杭州')}</span>
                  </div>
                  <Link to={`/${langInPath}/OfficeHZ#HZTop`}>
                    <span className="view-btn">{t('查看办公环境')}&nbsp;&gt;</span>
                  </Link>
                </div>
                <a href='https://s.xinrenxinshi.com/recruitGate/list#/ey=0ebdb0058d4a716b8f15cca6cc385e4089c663'>
                  <div className="deliver-btn">
                    <span className="btn-txt">{t('投递简历')}</span>
                  </div>
                </a>
              </div>
            </div>

            <div className="list-items">
              <div class="background city-2"></div>
              <div className="info-wrap">
                <div className="info-content">
                  <div className="city-location">
                    <i className="iconfont city-icon">&#xe697;</i>
                    <span className="city-name">{t('东京')}</span>
                  </div>
                  <Link to={`/${langInPath}/OfficeDJ#DJTop`}>
                    <span className="view-btn">{t('查看办公环境')}&nbsp;&gt;</span>
                  </Link>
                </div>
                <a href='https://s.xinrenxinshi.com/recruitGate/list#/ey=0ebdb0058d4a716b8f15cca6cc385e4089c663'>
                  <div className="deliver-btn">
                    <span className="btn-txt">{t('投递简历')}</span>
                  </div>
                </a>
              </div>
            </div>
            <div className="list-items">
              <div class="background city-3"></div>
              <div className="info-wrap">
                <div className="info-content">
                  <div className="city-location">
                    <i className="iconfont city-icon">&#xe697;</i>
                    <span className="city-name">{t('大连')}</span>
                  </div>
                  <Link to={`/${langInPath}/OfficeDL#DLTop`}>
                    <span className="view-btn">{t('查看办公环境')}&nbsp;&gt;</span>
                  </Link>
                </div>
                <a href='https://s.xinrenxinshi.com/recruitGate/list#/ey=0ebdb0058d4a716b8f15cca6cc385e4089c663'>
                  <div className="deliver-btn">
                    <span className="btn-txt">{t('投递简历')}</span>
                  </div>
                </a>
              </div>
            </div>
            <div className="list-items">
              <div class="background city-4"></div>
              <div className="info-wrap">
                <div className="info-content">
                  <div className="city-location">
                    <i className="iconfont city-icon">&#xe697;</i>
                    <span className="city-name">{t('广州')}</span>
                  </div>
                  <Link to={`/${langInPath}/OfficeGZ#GZTop`}>
                    <span className="view-btn">{t('查看办公环境')}&nbsp;&gt;</span>
                  </Link>
                </div>
                <a href='https://s.xinrenxinshi.com/recruitGate/list#/ey=0ebdb0058d4a716b8f15cca6cc385e4089c663'>
                  <div className="deliver-btn">
                    <span className="btn-txt">{t('投递简历')}</span>
                  </div>
                </a>
              </div>
            </div>
            <div className="list-items">
              <div class="background city-5"></div>
              <div className="info-wrap">
                <div className="info-content">
                  <div className="city-location">
                    <i className="iconfont city-icon">&#xe697;</i>
                    <span className="city-name">{t('香港')}</span>
                  </div>
                  <Link to={`/${langInPath}/OfficeXG#XGTop`}>
                    <span className="view-btn">{t('查看办公环境')}&nbsp;&gt;</span>
                  </Link>
                </div>
                <a href='https://s.xinrenxinshi.com/recruitGate/list#/ey=0ebdb0058d4a716b8f15cca6cc385e4089c663'>
                  <div className="deliver-btn">
                    <span className="btn-txt">{t('投递简历')}</span>
                  </div>
                </a>
              </div>
            </div>
            <div className="list-items">
              <div class="background city-6"></div>
              <div className="info-wrap">
                <div className="info-content">
                  <div className="city-location">
                    <i className="iconfont city-icon">&#xe697;</i>
                    <span className="city-name">{t('韩国金浦')}</span>
                  </div>
                  <Link to={`/${langInPath}/OfficeHG#HGTop`}>
                    <span className="view-btn">{t('查看办公环境')}&nbsp;&gt;</span>
                  </Link>
                </div>
                <a href='https://s.xinrenxinshi.com/recruitGate/list#/ey=0ebdb0058d4a716b8f15cca6cc385e4089c663'>
                  <div className="deliver-btn">
                    <span className="btn-txt">{t('投递简历')}</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* 走进SNIFF */}
        <div id='JoinSniff' className="join-sniff">
          <div className="join-top">
            <h1 className={classNames(['join-title'], { 'animate': enteringInViewport })}>

              <span>{t('走进SNIFF')}</span>
              <img src={officeAdorn} alt='' />
            </h1>
            <p>
              {t('一个有文化的团队才称得上是团队，他们拥有共同的目标、愿景、思想、沟通方式、类似的兴趣爱好等等，这样才称得上是有生命力的团队。')}
            </p>
          </div>
          <div ref={enteringRef} className="join-us-tab">
            <Tabs activeKey={items.find(i => i.key === location.hash)?.key || '#companyGoal1'} centered
              items={items}
              onChange={(key) => {
                window.location.hash = key
              }}
            />
            <Swiper spaceBetween={30} hashNavigation={{ watchState: true, }} modules={[HashNavigation]} className="mySwiper">
              <SwiperSlide data-hash="companyGoal1" style={{width: '100%', display: 'flex', justifyContent: 'center', height: 450}}>
                <div className="tab-item">
                  <div className="company-goal">
                    <div className='goal-wrap'>
                      <h1 className="goal-title">{t('企业愿景')}</h1>
                      <span className="goal-content">{t('成为世界一流的跨境电商综合系统服务商')}</span>
                    </div>
                  </div>
                  <div className={classNames(['tab_1'], { 'mask-animate': startgoal1MaskAnimate })}>
                    <div className='mask-wrap'>
                      <img ref={goal1} className="company-img" src={yuanjing} alt='' />
                    </div>
                  </div>

                  <div className="words">
                    <img className={classNames(["aixin"], goal1InViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startgoal1MaskAnimate })} src={s} alt='' />
                    <img className={classNames(["aixin"], goal1InViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startgoal1MaskAnimate })} src={n} alt='' />
                    <img className={classNames(["aixin"], goal1InViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startgoal1MaskAnimate })} src={i} alt='' />
                    <img className={classNames(["aixin"], goal1InViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startgoal1MaskAnimate })} src={f2} alt='' />
                    <img onAnimationEnd={() => setStartgoal1MaskAnimate(true)}  className={classNames(["aixin"], goal1InViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startgoal1MaskAnimate })} src={f1} alt='' />
                  </div>
                </div> 
              </SwiperSlide>
              <SwiperSlide data-hash="companyGoal2" style={{width: '100%', display: 'flex', justifyContent: 'center', height: 450}}>
                <div className="tab-item">
                  <div className="company-goal">
                    <div className='goal-wrap'>
                      <h1 className="goal-title">{t('企业使命')}</h1>
                      <span className="goal-content">{t('让世界贸易变得更简单')}</span>
                      <span className="goal-content-desc">{t('帮助中国制造走出去 他国制造走进来 实现全球买 全球卖')}</span>
                    </div>
                  </div>

                  <div className={classNames(['tab_2'], { 'mask-animate': startgoal2MaskAnimate })}>
                    <div className='mask-wrap'>
                      <img ref={goal2} className="company-img" src={shiming} alt='' />
                    </div>
                  </div>

                  <div className="words">
                    <img className={classNames(["aixin"], goal2InViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startgoal2MaskAnimate })} src={s} alt='' />
                    <img className={classNames(["aixin"], goal2InViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startgoal2MaskAnimate })} src={n} alt='' />
                    <img className={classNames(["aixin"], goal2InViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startgoal2MaskAnimate })} src={i} alt='' />
                    <img className={classNames(["aixin"], goal2InViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startgoal2MaskAnimate })} src={f2} alt='' />
                    <img onAnimationEnd={() => setStartgoal2MaskAnimate(true)}  className={classNames(["aixin"], goal2InViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startgoal2MaskAnimate })} src={f1} alt='' />
                  </div>
                </div> 
              </SwiperSlide>
              <SwiperSlide data-hash="companyGoal3" style={{width: '100%', display: 'flex', justifyContent: 'center', height: 450}}>
                <div className="tab-item">
                  <div className="company-goal">
                    <div className='goal-wrap'>
                      <h1 className="goal-title">{t('企业文化')}</h1>
                      <span className="goal-content">{t('感恩 、分享、助力客户、成就员工')}</span>
                    </div>
                  </div>
                  <div className={classNames(['tab_3'], { 'mask-animate': startgoal3MaskAnimate })}>
                    <div className='mask-wrap'>
                      <img ref={goal3} className="company-img" src={wenhua} alt='' />
                    </div>
                  </div>
                  <div className="words">
                    <img className={classNames(["aixin"], goal3InViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startgoal3MaskAnimate })} src={s} alt='' />
                    <img className={classNames(["aixin"], goal3InViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startgoal3MaskAnimate })} src={n} alt='' />
                    <img className={classNames(["aixin"], goal3InViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startgoal3MaskAnimate })} src={i} alt='' />
                    <img className={classNames(["aixin"], goal3InViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startgoal3MaskAnimate })} src={f2} alt='' />
                    <img onAnimationEnd={() => setStartgoal3MaskAnimate(true)}  className={classNames(["aixin"], goal3InViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startgoal3MaskAnimate })} src={f1} alt='' />
                  </div>

                </div> 
              </SwiperSlide>
              <SwiperSlide data-hash="companyGoal4" style={{width: '100%', display: 'flex', justifyContent: 'center', height: 450}}>
                <div className="tab-item">
                  <div className="company-goal">
                    <div className='goal-wrap'>
                      <h1 className="goal-title">{t('企业核心能力')}</h1>
                      <span className="goal-content">{t('多语种、智能化、专业性、前瞻性')}</span>
                    </div>
                  </div>
                  <div className={classNames(['tab_4'], { 'mask-animate': startgoal4MaskAnimate })}>
                    <div className='mask-wrap'>
                      <img ref={goal4} className="company-img" src={nengli} alt='' />
                    </div>
                  </div>
                  <div className="words">
                    <img className={classNames(["aixin"], goal4InViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startgoal4MaskAnimate })} src={s} alt='' />
                    <img className={classNames(["aixin"], goal4InViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startgoal4MaskAnimate })} src={n} alt='' />
                    <img className={classNames(["aixin"], goal4InViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startgoal4MaskAnimate })} src={i} alt='' />
                    <img className={classNames(["aixin"], goal4InViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startgoal4MaskAnimate })} src={f2} alt='' />
                    <img onAnimationEnd={() => setStartgoal4MaskAnimate(true)}  className={classNames(["aixin"], goal4InViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startgoal4MaskAnimate })} src={f1} alt='' />
                  </div>
                </div> 
              </SwiperSlide>
              <SwiperSlide data-hash="companyGoal5" style={{width: '100%', display: 'flex', justifyContent: 'center', height: 450}}>
                <div className="tab-item">
                  <div className="company-goal">
                    <div className='goal-wrap'>
                      <h1 className="goal-title">{t('企业核心价值观')}</h1>
                      <span className="goal-content">{t('感恩分享、不断创新、成就客户、贡献社会')}</span>
                    </div>
                  </div>

                  <div className={classNames(['tab_5'], { 'mask-animate': startgoal5MaskAnimate })}>
                    <div className='mask-wrap'>
                      <img ref={goal5} className="company-img" src={jiazhiguan} alt='' />
                    </div>
                  </div>
                  <div className="words">
                    <img className={classNames(["aixin"], goal5InViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startgoal5MaskAnimate })} src={s} alt='' />
                    <img className={classNames(["aixin"], goal5InViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startgoal5MaskAnimate })} src={n} alt='' />
                    <img className={classNames(["aixin"], goal5InViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startgoal5MaskAnimate })} src={i} alt='' />
                    <img className={classNames(["aixin"], goal5InViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startgoal5MaskAnimate })} src={f2} alt='' />
                    <img onAnimationEnd={() => setStartgoal5MaskAnimate(true)}  className={classNames(["aixin"], goal5InViewport ? ['animate__animated', 'animate__bounce'] : [], { 'animate__fadeOut': startgoal5MaskAnimate })} src={f1} alt='' />
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
        {/* 底部菜单 */}
        <FooterNav />
    </>
  );
}

export default JoinUs;
