import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";

// 轮播
import { Navigation, Autoplay } from 'swiper/modules';
import { Swiper, useSwiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import Nav from '@/components/Nav1';
import './index.scss';
import hangzhou1 from '@/assets/hangzhou_1.png'
import hangzhou2 from '@/assets/hangzhou_2.png'
import hangzhou3 from '@/assets/hangzhou_3.png'
import left from '@/assets/swipe_left.png'
import right from '@/assets/swipe_right.png'

const SwiperButtonNext = ({ children }) => {
  const swiper = useSwiper();
  return (
    <span
      slot="container-start"
      onClick={() => swiper.slideNext()}
      className="swiper-button-prev"
      style={{
        right: "var(--swiper-navigation-sides-offset, 10px)",
        left: "auto",
      }}
    >
      <img className='swiper-btn' src={right} alt='' />
    </span>
  );
};

const SwiperButtonPrev = ({ children }) => {
  const swiper = useSwiper();
  return (
    <span
      onClick={() => swiper.slidePrev()}
      className="swiper-button-next"
      style={{
        left: "var(--swiper-navigation-sides-offset, 10px)",
        right: "auto",
      }}
      slot="container-start"
    >
      <img className='swiper-btn' src={left} alt='' />
  </span>
  );
};

function OfficeHZ() {
  const { t } = useTranslation();
  const { lang: langInPath } = useParams();
  
  return (
    <div id='HZTop' className="office-page flex-col">
        {/* 菜单栏 */}
      <div className="news-menu-group">
        <Nav lang={langInPath} />
      </div>
      <div className="office-wrapper">
        <h1 className="office-title">{t('选择你喜欢的城市办公')}&nbsp;—&nbsp;{t('杭州')}</h1>
        <div className="office-text-wrap">
          <div className="office-paragraph">
            {t('杭州自古以来就是我国著名的经济文化中心，流淌千年运的河，滋养出杭州独有的贸易灵魂。')}
          </div>
          <div className="office-paragraph">
            {t('杭州也是SNIFF的起点，是一切有关“让贸易变得更简单”的愿景起航的地方。这里有SNIFF中国供应链部门、日语客户支持部门、跨境电商运营部门、平台运营部门、技术开发分公司、萧山仓储物流分公司、西子精简分公司等核心业务部门，是SNIFF的大本营。')}
          </div>
          <div className="office-paragraph">
            {t('在这座钟灵毓秀的城市里，我们工作、我们生活，我们努力，我们成长，在这里我们和SNIFF一起奋斗，书写出独一无二的贸易，与众不同的你我。SNIFF欢迎你的加入！')}
          </div>
        </div>
        <div className="office-group">
          <Swiper
            navigation={false}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            modules={[Navigation, Autoplay]}
            className="row-wrap mySwiper"
          >
          <SwiperButtonPrev />
          <SwiperButtonNext />
            <SwiperSlide style={{width: '100%', display: 'flex', justifyContent: 'center', height: 600}}>
              <img className="block_1" src={hangzhou1} alt='' />
            </SwiperSlide>
            <SwiperSlide style={{width: '100%', display: 'flex', justifyContent: 'center', height: 600}}>
              <img className="block_1" src={hangzhou2} alt='' />
            </SwiperSlide>
            <SwiperSlide style={{width: '100%', display: 'flex', justifyContent: 'center', height: 600}}>
              <img className="block_1" src={hangzhou3} alt='' />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>


    </div>
  );
}

export default OfficeHZ;
