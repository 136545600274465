import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useParams } from "react-router-dom";
import { useInViewport } from 'ahooks';

// 轮播
import { Navigation,Pagination, Autoplay } from 'swiper/modules';
import { Swiper, useSwiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './index.scss'
import product from '@/assets/产品出海.png'
import SupplyChain from '@/assets/供应链出海.png'
import software from '@/assets/软件出海.png'
import brand from '@/assets/品牌出海.png'
import left from '@/assets/swipe_left.png'
import right from '@/assets/swipe_right.png'

import oulu from '@/assets/oulu.png'
import uppervoid from '@/assets/uppervoid.png'
import tyakasha from '@/assets/tyakasha.png'
import strl from '@/assets/strl.png'
import black_deer from '@/assets/black_deer.png'

import street from '@/assets/714street.png'

import 'animate.css';

import topicAdorn from '@/assets/line_2.png';
import classNames from 'classnames';

const SwiperButtonNext = ({ children }) => {
  const swiper = useSwiper();
  return (
    <span
      slot="container-start"
      onClick={() => swiper.slideNext()}
      className="swiper-button-prev"
      style={{
        right: "var(--swiper-navigation-sides-offset, 10px)",
        left: "auto",
      }}
    >
      <img className='swiper-btn' src={right} alt='' />
    </span>
  );
};

const SwiperButtonPrev = ({ children }) => {
  const swiper = useSwiper();
  return (
    <span
      onClick={() => swiper.slidePrev()}
      className="swiper-button-next"
      style={{
        left: "var(--swiper-navigation-sides-offset, 10px)",
        right: "auto",
      }}
      slot="container-start"
    >
      <img className='swiper-btn' src={left} alt='' />
  </span>
  );
};

function useSwiperAutoplay(swiperDomRef, swiperRef) {
  useEffect(() => {
    if (!swiperDomRef.current && !swiperRef.current) {
      return
    }
    const swiperContainer = swiperDomRef.current;
    const swiperInstance = swiperRef.current;

    const handleMouseEnter = () => {
      swiperInstance.autoplay.stop();
    };

    const handleMouseLeave = () => {
      swiperInstance.autoplay.start();
    };

    swiperContainer.addEventListener('mouseenter', handleMouseEnter);
    swiperContainer.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      swiperContainer.removeEventListener('mouseenter', handleMouseEnter);
      swiperContainer.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [swiperDomRef, swiperRef]);
}


const AboutCorp = (props, ref) => {
  const { t } = useTranslation();
  const { lang: langInPath } = useParams();
  const pagination = {
    clickable: false,
    renderBullet: function (index, className) {
      return '<span class="' + className + '"></span>';
    },
  };

  const swiperDomRef = useRef(null);
  const swiperRef = useRef(null);

  useSwiperAutoplay(swiperDomRef, swiperRef);

  const onSlideChange  = (e) => {
    // 当播放到品牌的时候
    if (e.realIndex === 3) {
      playBrandAnimation();
    } else {
      stopBrandAnimation();
    }
  }
  
  const [brandAnimateClass, setBrandAnimateClass] = useState([]);
  const [dataAnimateClass, setDataAnimateClass] = useState(['animate__animated', 'animate__bounce', 'animate__fast']);

  const brandTimeIDRef = useRef();
  const dataTimeIDRef = useRef();
  
  const playBrandAnimation = () => {
    setBrandAnimateClass(['animate__animated', 'animate__bounce', 'animate__fast']);
  }

  const stopBrandAnimation = () => {
    setBrandAnimateClass([]);
    // loop stop
    clearTimeout(brandTimeIDRef.current);
  }

  const onBrandAnimationEnd = () => {
    setBrandAnimateClass([]);
    brandTimeIDRef.current = setTimeout(() => {
      playBrandAnimation();
    }, 1000);
  }

  const onDataAnimationEnd = () => {
    setDataAnimateClass([]);
    dataTimeIDRef.current = setTimeout(() => {
      setDataAnimateClass(['animate__animated', 'animate__bounce', 'animate__fast']);
    }, 1000);
  }

  const titleHeartRef = useRef(null)
  const [titleHeartInViewport] = useInViewport(titleHeartRef)

  return (
    <div className="corp-group" ref={ref}>
      <div className="corp-wrap">
        {/* <h1 className="topic-title"> */}
        <h1 ref={titleHeartRef} className={classNames(['topic-title'], { 'move-up': titleHeartInViewport })}>
          <span>{t('让资源与资源的连接更简单')}</span>
          <img className="adorn-img" src={topicAdorn} alt='' />
        </h1>
        <Swiper
          ref={swiperDomRef}
          onSwiper={(swiper) => swiperRef.current = swiper}
          onSlideChange={onSlideChange}
          pagination={pagination}
          navigation={false}
          loop={true}
          modules={[Navigation, Pagination, Autoplay]}
          slidesPerView={1}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          className="aboutSwiper"
        >
          <SwiperButtonPrev />
          <SwiperButtonNext />
          <SwiperSlide style={{width: '100%', display: 'flex', justifyContent: 'center', height: '45vw'}}>
            <div className="row-left">
              <div className="topic flex-row">
                <span className="topic-num">01</span>
                <span className="topic-name">{t('产品出海')}</span>
              </div>
              <p className="topic-txt">
              {t('随着我国产能不断提升，国内市场日趋饱和，为寻求进一步的发展，开拓市场，产品出海成为各企业的必要选择。但产品出海往往会遭遇销售网络阻塞，文化差异过大、贸易壁垒高筑等多方挑战。')}
              </p>
              <p className="topic-txt">
              {t('SNIFF集团多年来深耕跨境电商领域，建立了专业完备的海内外团队，通过专业化的作业机制，帮助各类企业完成产品出海。目前已涵盖服装、日用百货、户外产品、运动产品、家具装饰品等各类产品。SNIFF致力于帮助中国优秀产品走向海外，平均每月向海外输出货物超2000万件！')}
              </p>
              
              <div className="data-wrap flex-row">
                <div className="data-group">
                  <span className="data-title">{t('注册用户数')}</span>
                  <span className="data-num">{t('10万+')}</span>
                </div>
                <div className="data-group">
                  <span className="data-title">{t('月出口商品数')}</span>
                  <span className="data-num">{t('1500万件+')}</span>
                </div>
                <div className="data-group">
                  <span className="data-title">{t('可寄往多国')}</span>
                  <span className="data-num">{t('50+')}</span>
                </div>
              </div>
              <Link to={`/${langInPath}/About#BusinessGroup`}>
                <div className="contact-btn flex-row justify-between">
                  <span className="contact-txt">{t('商务咨询')}</span>
                  <i className="iconfont contact-icon">&#xe6a2;</i>
                </div>
              </Link>
            </div>
            <div className="row-right">
              <img className='topic-img' src={product} alt='product' />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{width: '100%', display: 'flex', justifyContent: 'center', height: '45vw'}}>
            <div className="row-left">
              <div className="topic flex-row">
                <span className="topic-num">02</span>
                <span className="topic-name">{t('供应链出海')}</span>
              </div>
              <p className="topic-txt">
                {t('SNIFF合作多家了供应链工厂，强大的供应链优势，让SNIFF可以更好的满足客户订购、采购、OEM需求，为客户提供高效、快速、可靠的服务。')}
              </p>
              <p className="topic-txt">
                {t('产能过剩和国内市场的饱和让许多产品滞留在仓库中，而无法流通、无法被使用。SNIFF注重资源的合理利用，因此我们将供应链出海作为一个重要的战略方向。帮助供应链出海在增加贸易产值的同时，可以让我们的资源在合适的地方发挥作用，避免产品囤积，造成资源浪费。')}
              </p>
              <div className="data-wrap flex-row">
                <div className="data-group">
                  <span className="data-title">{t('注册用户数')}</span>
                  <span className="data-num">{t('10万+')}</span>
                </div>
                <div className="data-group">
                  <span className="data-title">{t('月出口商品数')}</span>
                  <span className="data-num">{t('1500万件+')}</span>
                </div>
                <div className="data-group">
                  <span className="data-title">{t('可寄往多国')}</span>
                  <span className="data-num">{t('50+')}</span>
                </div>
                <div className="data-group">
                  <span className="data-title">{t('系统接入供应链数')}</span>
                  <span className="data-num">{t('20万+')}</span>
                </div>
              </div>
              <Link to={`/${langInPath}/About#BusinessGroup`}>
                <div className="contact-btn flex-row justify-between">
                  <span className="contact-txt">{t('商务咨询')}</span>
                  <i className="iconfont contact-icon">&#xe6a2;</i>
                </div>
              </Link>
            </div>
            <div className="row-right">
              <img className='topic-img' src={SupplyChain} alt='SupplyChain' />
            </div>
          </SwiperSlide>
          <SwiperSlide style={{width: '100%', display: 'flex', justifyContent: 'center', height: '45vw'}}>
            <div className="row-left">
              <div className="topic flex-row">
                <span className="topic-num">03</span>
                <span className="topic-name">{t('软件出海')}</span>
              </div>
              <p className="topic-txt">
                {t('SNIFF凭借丰富的海内外经验，助力国内优秀App迈向全球市场。SNIFF与鸥鹭合作，依靠大数据为跨境卖家提供精确选品、精准营销，精细运营的发展方案。通过大数据能精准的捕捉行业变化，准确定位优质竞品，深度剖析市场流量表现，从而帮助跨境卖家构建更科学立体的运营体系。这是跨境卖家突破海外发展瓶颈，实现自身增长的重要力量。')}
              </p>
              <p className="topic-txt">
                {t('SNIFF多年来不断帮助更多的国内优秀App走向世界，坚持依靠科技的力量，推动行业发展，让贸易世界变得更简单。')}
              </p>
              <div className="data-wrap flex-row">
                <div className="data-group">
                  <span className="data-title">{t('注册用户数')}</span>
                  <span className="data-num">{t('10万+')}</span>
                </div>
                <div className="data-group">
                  <span className="data-title">{t('月出口商品数')}</span>
                  <span className="data-num">{t('1500万件+')}</span>
                </div>
                <div className="data-group">
                  <span className="data-title">{t('可寄往多国')}</span>
                  <span className="data-num">{t('50+')}</span>
                </div>
                <div className="data-group">
                  <span className="data-title">{t('系统接入供应链数')}</span>
                  <span className="data-num">{t('20万+')}</span>
                </div>
              </div>
              <Link to={`/${langInPath}/About#BusinessGroup`}>
                <div className="contact-btn flex-row justify-between">
                  <span className="contact-txt">{t('商务咨询')}</span>
                  <i className="iconfont contact-icon">&#xe6a2;</i>
                </div>
              </Link>
            </div>
            <div className="row-right">
              <div className='img-wrap'>
                <img className='topic-img' src={software} alt='software' />
                <img onAnimationEnd={onDataAnimationEnd} className={classNames(['topic-icon'], dataAnimateClass)} src={oulu} alt='software' />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide type="brand" style={{width: '100%', display: 'flex', justifyContent: 'center', height: '45vw'}}>
            <div className="row-left">
              <div className="topic flex-row">
                <span className="topic-num">04</span>
                <span className="topic-name">{t('品牌出海')}</span>
              </div>
              <p className="topic-txt">
                {t('比起高性价比的产品，中国品牌的出海之路走的尤为艰难。面对着海外品牌认知度低，品牌声量小等多方困境。SNIFF长期以来，积极帮助国产品牌走出国门，完成海外品牌构建、品牌经营和品牌推广。至今已帮助各品类数百个品牌完成出海，并长期为品牌的海外发展提供支持。')}
              </p>
              <p className="topic-txt">
                {t('同时，为避免海外用户陷于低价、低质、抄袭产品的漩涡中，SNIFF更鼓励海外客户分销供货稳定、具有品质保证、原创设计的品牌商品。目前已合作涵服饰、箱包、户外用品等各类目在内，共计超过200多家中国品牌。成功助力日本电商客户达成一年50亿日元销售额、一年5000万的采购额的瞩目成就。')}
              </p>
              <div className="data-wrap flex-row">
                <div className="data-group">
                  <span className="data-title">{t('注册用户数')}</span>
                  <span className="data-num">{t('10万+')}</span>
                </div>
                <div className="data-group">
                  <span className="data-title">{t('月出口商品数')}</span>
                  <span className="data-num">{t('1500万件+')}</span>
                </div>
                <div className="data-group">
                  <span className="data-title">{t('可寄往多国')}</span>
                  <span className="data-num">{t('50+')}</span>
                </div>
                <div className="data-group">
                  <span className="data-title">{t('助力中国品牌')}</span>
                  <span className="data-num">{t('200+')}</span>
                </div>
              </div>
              <Link to={`/${langInPath}/About#BusinessGroup`}>
                <div className="contact-btn flex-row justify-between">
                  <span className="contact-txt">{t('商务咨询')}</span>
                  <i className="iconfont contact-icon">&#xe6a2;</i>
                </div>
              </Link>
            </div>
            <div className="row-right">
              <img className='topic-img' src={brand} alt='brand' />
              <img className={classNames(['topic-icon1', 'animate__delay-1s'], brandAnimateClass)} src={street} alt='software' />
              <img className={classNames(['topic-icon2', 'animate__delay-2s'], brandAnimateClass)} src={uppervoid} alt='software' />
              <img className={classNames(['topic-icon3', 'animate__delay-3s'], brandAnimateClass)} src={tyakasha} alt='software' />
              <img className={classNames(['topic-icon4', 'animate__delay-4s'], brandAnimateClass)} src={strl} alt='software' />
              <img onAnimationEnd={onBrandAnimationEnd} className={classNames(['topic-icon5', 'animate__delay-5s'], brandAnimateClass)} src={black_deer} alt='software' />
            </div>
          </SwiperSlide>
        </Swiper>
        </div>
  </div>
  )
}

export default forwardRef(AboutCorp)