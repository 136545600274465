import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from "react-router-dom";

import Nav from '@/components/Nav1';

import './index.scss';
import axios from 'axios';
import { useRequest } from 'ahooks';
import { Link } from 'react-router-dom';
import moment from 'moment';

const articleStationLangMap = {
  '英文': 'en',
  '中文': 'cn'
}

function getArtcleDetailByNo(no) {
  if (no) {
    return axios({ url: `https://static-s.theckb.com/sniffGroup/article/articleItems/${no}.prod.json` })
  }
  return Promise.reject()
}

function getArtcles() {
  return axios({ url: `https://static-s.theckb.com/sniffGroup/article/articleList.prod.json?_time=${new Date().getTime()}`})
}

function NewsDetails() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const {no: artcleNo} = useParams();
  const location = useLocation();
  const { lang: langInPath } = useParams();
  const [prevNextArticles, setPrevNextArticles] = useState({
    prev: null,
    next: null
  })

  const [article, setArticle] = useState({});
  const { run } = useRequest(getArtcleDetailByNo, {
    async onSuccess(data)  {
      setArticle(data.data);
      
      // 将页面语言调整到文章语言
      const artcleLang = articleStationLangMap[data.data.station];
      const pageInPath = location.pathname.replace('/' + langInPath, '')
      if (artcleLang === 'en' || artcleLang === 'cn') {
        navigate(`/${artcleLang}${pageInPath}`)
        i18n.changeLanguage(artcleLang)
      }
      
      var article = data.data
      const { data: artcles } = await getArtcles();
      var articleIndex = null;

      console.log('article', article)
      // console.log('article.station', article.station)
      console.log('article.classification', article.classification)
  
      const _artcles = artcles
      .filter(({ title, enTitle }) => {
        if(langInPath === 'en' && enTitle) {
          return true
        }
        if (langInPath === 'cn' && title) {
          return true
        }
        return false;
      })
      .filter(({ classification }) => classification === article.classification)

      _artcles.forEach((article, index) => {
        if (article.no === artcleNo) {
          articleIndex = index;
        }
      })

      console.log('articleIndex', articleIndex)
      
      if (articleIndex != null) {
        setPrevNextArticles({
          prev: _artcles[articleIndex - 1],
          next: _artcles[articleIndex + 1]
        })
      }
    }
  });
  
  useEffect(() => {
    if (artcleNo) {
      run(artcleNo)
    }
  }, [artcleNo, run])

  function createEvent() {
    if( langInPath === 'en') {
      return {__html: article.enContent};
    } else {
      return {__html: article.content};
    }
  }
  
  console.log('prevNextArticles', prevNextArticles)

  return (
    <div className="news-page flex-col">
        {/* 菜单栏 */}
      <div className="news-menu-group">
        <Nav lang={langInPath} />
      </div>
      {/* 文章详情 */}
      <div className="article-group">
        <h1 className="article-title">{langInPath === 'en' ? article.enTitle : article.title}</h1>
        <p className="article-date">{moment(article.date).format('YYYY')}-{moment(article.date).format('MM')}-{moment(article.date).format('DD')}</p>
        <div className='article-content' dangerouslySetInnerHTML={createEvent()}></div>
        <div className="article-entry">
          {
            prevNextArticles.prev && <Link to={`/${langInPath}/NewsDetails/${prevNextArticles?.prev?.no}`} className="entry">&lt;{t('上一篇')}：{langInPath === 'cn' ? prevNextArticles?.prev?.title : prevNextArticles?.prev?.enTitle}</Link>
          }
          {
            prevNextArticles.next && <Link to={`/${langInPath}/NewsDetails/${prevNextArticles?.next?.no}`} className="entry">&lt;{t('下一篇')}：{langInPath === 'cn' ? prevNextArticles?.next.title : prevNextArticles?.next?.enTitle}</Link>
          }
        </div>
      </div>

    </div>
  );
}

export default NewsDetails;
