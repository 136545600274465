import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { Select } from 'antd';
import './index.scss';

import whiteLogo from '@/assets/sniff_white.png';
import colorLogo from '@/assets/sniff_color.png';

import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';


const useScrollDirection = () => {
  const [scrollY, setScrollY] = useState(0);
  const [scrollDirection, setScrollDirection] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > scrollY) {
        setScrollDirection('down');
      } else if (currentScrollY < scrollY) {
        setScrollDirection('up');
      }

      setScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollY]);

  return [scrollDirection, scrollY]
}

function defaultTheme(themes) {
  if (!themes) {
    return { 'sniff-nav-light': true }
  }
  const keys = Object.keys(themes)
  for (let k of keys) {
    if (themes[k]) {
      return { [k]: true }
    }
  }
  return { 'sniff-nav-light': true }
}

const Nav = (props) => {
  const { t, i18n } = useTranslation();
  const [scrollDirection] = useScrollDirection();
  const navigate = useNavigate();
  const { lang: langInPath } = useParams();

  const switchBgStyleAt = useRef({height: 0});

  useEffect(() => {
    var view = document.querySelector(props.classLocation);
    if (view) {
      switchBgStyleAt.current = view.getBoundingClientRect();
    }
  }, [props.classLocation])

  const langOptions = [
    { value: 'cn', label: '中文' },
    { value: 'en', label: 'English'},
  ];

  const [langOption, setLangOption] = useState(langInPath);

  const location = useLocation();

  // const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');
  // useEffect(() => {
  //   // 在语言值发生变化时更新本地存储
  //   localStorage.setItem('language', language);
  // }, [language]);


  function onLangChange(lang) {
    setLangOption(lang)
    localStorage.setItem('language', lang);
    const pageInPath = location.pathname.replace('/' + langInPath, '')
    if (langInPath === 'en' || langInPath === 'cn') {
      const changedLang = lang
      navigate(`/${changedLang}${pageInPath}`)
      i18n.changeLanguage(changedLang)
    }
  }

  return (
    <>
      <div className={classNames(['home-group', 'header-menu'], {
          ...defaultTheme(props.themes),
          'header-menu-down-show': scrollDirection === 'up',
          'header-menu-up-close': scrollDirection === 'down'
        })}>
        <div className='home-menu'>
          <img className="logo-img light" src={colorLogo} alt='logo' onClick={() => { navigate('/') }} />
          <img className="logo-img dark" src={whiteLogo} alt='logo' onClick={() => { navigate('/') }} />
          <ul className="top-menu-wrap">
            <li className="menu-item">
              <Link className={classNames(['menu-a', {
                'active': location.pathname.includes('/Home')
              }])} to={`/${props.lang}/Home`}>{t('首页')}</Link>
            </li>
            <li className="menu-item">
              <Link className={classNames(['menu-a', {
                'active': location.pathname.includes('/About')
                // #AboutGroup
              }])} to={`/${props.lang}/About`}>{t('了解直行便')}</Link>
            </li>
            <li className="menu-item">
              <Link className={classNames(['menu-a', {
                'active': location.pathname.includes('/News')
                // #ArticleContent
              }])} to={`/${props.lang}/News`}>{t('最新资讯')}</Link>
            </li>
            <li className="menu-item">
              <Link className={classNames(['menu-a', {
                'active': location.pathname.includes('/JoinUs')
              }])} to={`/${props.lang}/JoinUs#Possibility`}>{t('加入我们')}</Link>
            </li>
          </ul>
        </div>
        
        <div className='lang-change-wrap'>
          <i className='iconfont lang-change-icon'>&#xe69d;</i>
          <Select
            popupMatchSelectWidth={160}
            popupClassName={classNames(['lang-select-popup'], defaultTheme(props.themes))}
            value={langInPath}
            bordered={false}
            className="lang-select"
            defaultValue={langOption}
            onChange={onLangChange}
            options={langOptions}
            suffixIcon={<i className="iconfont arrow-icon">&#xe6a4;</i>}
          />
        </div>
      </div>
    </>
  )
}

export default Nav