import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "首页": "Home Page",
      "了解直行便": "Learn More About THE CKB", 
      "最新资讯": "Latest News",
      "加入我们": "Join Us",
      // "让世界贸易变得更简单": "Make World Trade More Easier",
      "生产能力提高带来产量盈余，额外的生产和加工造成了资源的严重浪费": "The increase in production capacity has brought about a surplus in production, and additional production and processing have caused a serious waste of resources",
      "过度浪费加速了资源的消耗，对环境也造成了巨大的伤害，我们有责任确保资源被有效利用": "Excessive waste accelerates the consumption of resources and causes great harm to the environment. We have the responsibility to ensure that resources are used effectively",
      "合理的资源分配对减少浪费、延缓资源消耗进程至关重要": "Reasonable resource allocation is crucial to reducing waste and delaying the process of resource consumption",
      "我们立志于资源与资源的透明连接，构建全新的资源再分配系统，让资源的流动被看见": "We are committed to the transparent connection of resources and build a new resource redistribution system so that the flow of resources can be seen",
      "滑动浏览更多": "Swipe to learn more",
      // 资源
      "让资源与资源的连接更简单": "Make it easier to resource connect ",
      "产品出海": "Products Global Expansion",
      "随着我国产能不断提升，国内市场日趋饱和，为寻求进一步的发展，开拓市场，产品出海成为各企业的必要选择。但产品出海往往会遭遇销售网络阻塞，文化差异过大、贸易壁垒高筑等多方挑战。": "As China's production capacity continues to increase, the domestic market is becoming increasingly saturated. In order to seek further development and open up markets, exporting products to overseas has become a necessary choice for various enterprises.However, products going overseas often encounter multiple challenges such as sales network congestion, excessive cultural differences, and high trade barriers.",
      "SNIFF集团多年来深耕跨境电商领域，建立了专业完备的海内外团队，通过专业化的作业机制，帮助各类企业完成产品出海。目前已涵盖服装、日用百货、户外产品、运动产品、家具装饰品等各类产品。SNIFF致力于帮助中国优秀产品走向海外，平均每月向海外输出货物超2000万件！": "SNIFF Group has been deeply involved in the field of cross-border e-commerce for many years, and has established a professional and complete team at home and abroad. Through a professional operation mechanism, it helps various enterprises to complete their products overseas. Including clothing,Daily necessities, outdoor products, sports products, furniture decorations and other products. SNIFF is committed to helping excellent Chinese products go overseas, with an average monthly Over 20 million pieces of goods have been exported overseas!",
      "SNIFF多年来不断帮助更多的国内优秀App走向世界，坚持依靠科技的力量，推动行业发展，让贸易世界变得更简单。": "Over the years, SNIFF has been helping more domestic excellent apps to go global, insisting on relying on the power of technology to make the trading world easier.",
      
      "供应链出海": "Supply Chain Global Expansion",
      "SNIFF合作多家了供应链工厂，强大的供应链优势，让SNIFF可以更好的满足客户订购、采购、OEM需求，为客户提供高效、快速、可靠的服务。": "SNIFF has cooperated with a number of supply chain factories, and the strong supply chain advantages enable SNIFF to better meet customer ordering, procurement, OEM needs, and serve customers Provide efficient, fast and reliable service.",
      "产能过剩和国内市场的饱和让许多产品滞留在仓库中，而无法流通、无法被使用。SNIFF注重资源的合理利用，因此我们将供应链出海作为一个重要的战略方向。帮助供应链出海在增加贸易产值的同时，可以让我们的资源在合适的地方发挥作用，避免产品囤积，造成资源浪费。": "Overcapacity and the saturation of the domestic market have left many products stuck in warehouses, unable to circulate or be used. SNIFF pays attention to the rational use of resources, Therefore, we regard supply chain going overseas as an important strategic direction. Helping the supply chain to go overseas can increase the value of trade and at the same time make our resources suitable The place plays a role to avoid product hoarding and waste of resources.",
      
      "软件出海": "Software Global Expansion",  
      "SNIFF凭借丰富的海内外经验，助力国内优秀App迈向全球市场。SNIFF与鸥鹭合作，依靠大数据为跨境卖家提供精确选品、精准营销，精细运营的发展方案。通过大数据能精准的捕捉行业变化，准确定位优质竞品，深度剖析市场流量表现，从而帮助跨境卖家构建更科学立体的运营体系。这是跨境卖家突破海外发展瓶颈，实现自身增长的重要力量。": 
      "With the rich experience at home and abroad, SNIFF helps domestic excellent apps walk into the global market. SNIFF cooperates with Oalur Relying on rich data to provide the accurate product selection, precision marketing and sophisticated operation development plan for cross-border sellers . Through mega data , we capture industry changes, locate high-quality competing products  accurately , and deeply analyze market traffic performance. This helps cross-border sellers build a more scientific and three-dimensional operating system. This is an important force for cross-border sellers to break through the bottleneck of overseas development and achieve their own growth.",      
      "品牌出海": "Brand Global Expansion",
      
      "比起高性价比的产品，中国品牌的出海之路走的尤为艰难。面对着海外品牌认知度低，品牌声量小等多方困境。SNIFF长期以来，积极帮助国产品牌走出国门，完成海外品牌构建、品牌经营和品牌推广。至今已帮助各品类数百个品牌完成出海，并长期为品牌的海外发展提供支持。": "Compared with cost-effective products, the road to overseas for Chinese brands is particularly difficult. Faced with many difficulties such as low awareness of overseas brands and low brand voice. For a long time, Sniff has actively helped domestic brands go abroad and completed overseas brand building, brand management and brand promotion. So far, it has helped hundreds of brands in various categories to complete Go overseas and provide long-term support for the brand's overseas development.",
      "同时，为避免海外用户陷于低价、低质、抄袭产品的漩涡中，SNIFF更鼓励海外客户分销供货稳定、具有品质保证、原创设计的品牌商品。目前已合作涵服饰、箱包、户外用品等各类目在内，共计超过200多家中国品牌。成功助力日本电商客户达成一年50亿日元销售额、一年5000万的采购额的瞩目成就。": "At the same time, in order to prevent overseas users from falling into the vortex of low-priced, low-quality, and plagiarized products, SNIFF encourages overseas customers to distribute and supply stable, quality-assured, original products. Designed branded merchandise. At present, it has cooperated with more than 200 Chinese brands including clothing, luggage, outdoor products and other categories. Successfully helped Japanese e-commerce customers The households have achieved remarkable achievements in annual sales of 5 billion yen and annual purchases of 50 million.",
      "注册用户数": "Registered users",
      "月出口商品数": "The number of exported goods per month",
      "可寄往多国": "Can ship to more countries",
      "系统接入供应链数": "Supply Chain Resources in our system",
      "助力中国品牌": "Help Chinese brands",
      "10万+": "100,000+",
      "1500万件+": "15 million+",
      "50+": "50+",
      "20万+": "200,000+",
      "200+": "200+",
      "商务咨询": "Business Consulting",
      
      // 集团资讯
      "进击的SNIFF「 集团资讯 」": 'SNIFF "Group Information"',
      "查看更多": "Learn more",

      // 加入我们
      "用年轻人的方式改变世界!": "Change the world with young people's way",
      "更运动，更开放，更灵活": "More athletic spirit, more open, more flexible",

      // 底部菜单
      "直行便优势": "THE CKB Advantage",
      "业务咨询": "Business Consultation",
      "企业合作": "Enterprise Cooperation",
      "产品动态": "Product News",
      "公告": "Announcement",
      "工作机会": "Job Opportunities",
      "贴心福利": "Warm Benefits",
      "办公城市": "Office City",
      "走进SNIFF": "Walk Into SNIFF",
      "联系我们": "Contact Us",

      // 了解直行便 二级页 第一屏
      "100秒了解直行便": "100 seconds to know THE CKB",
      "SNIFF GROUP 运营的THE CKB是一家什么样的公司？我们将在视频中详细讲解THE CKB提供的进口业务相关解决方案。": "What kind of company is THE CKB operated by SNIFF GROUP? We will explain in detail the import business solutions provided by THECKB in the video",
      
      // 了解直行便 二级页 第三屏
      "直行便业务咨询": "THE CKB business consulting",
      "电话号码": "Phone number",
      "工作时间": "Working hours",
      "立即咨询": "Consult now",
      "电子邮件": "E-mail",
      "THE 直行便": "THECKB",
      '北京': 'Beijing',

      // 最新资讯
      // previous page, next page
      // 文字按钮: 上一篇    下一篇
      "上一篇": "previous page",
      "下一篇": "next page",
      // Total 10 items
      '共': 'Total',
      '条': 'items',

      


      // 加入我们
      "我们立志成为全球优秀雇佣公司": "We are a global excellent employment company",
      "我们的目标：消除办公场地、时间、距离限制、享受工作 与生活平衡的乐趣": "Our goal: to reduce office space, time, distance restrictions, and enjoy the joy of work-life balance",
      "立即投递简历": "Submit resume now",
      "SNIFF贴心福利": "SNIFF warm benefits",
      "爱心": "Love",
      "定期发放宠物基金，让铲屎官们轻松陪伴爱宠": "Regular distribution of pet funds, easily accompany their pets",
      "Sniff Group 是一个温暖有爱的公司，我们深知宠物是铲屎官们非常重要的朋友和家人。为了让员工能更轻松的享受爱宠的陪伴，帮助员工照顾宠物，公司提供了专属的宠物福利——“宠物基金”。宠物基金每月按时发放，公司用实际行动为萌宠加餐啦！": 'Sniff Group is a warm and caring company, we know that pets are very important friends and family members. In order to make it easier for employees to enjoy the company of pets,to help employees take care of pets, the company provides exclusive pet benefits - "Pet Fund". The pet fund is distributed on time every month, and the company uses practical actions to add meals to the cute pets!',
      "孝心": "Filial piety",
      "年末向员工父母发放慰问基金，感恩她们的付出": "Distribute condolence funds to employees' parents at the end of the year, thanking them for their efforts",
      "父母德高，子女良教。Sniff Group 在感念每一位员工为公司作出的奉献的同时，也感谢他们的父母为社会和公司培养了诸多优秀人才。对于父母的辛苦付出，Sniff 感念于心，感恩于行。为父母设立了感恩基金，每年直接向父母打款，以此来表达Sniff 集团对父母的尊敬和感谢。": "Parents are virtuous and children are taught well. Sniff Group is grateful for the dedication of every employee to the company, and also thanks their parents for cultivating many outstanding talents for the society and the company. For the hard work of his parents, Sniff is grateful and grateful. Set up a gratitude fund for parents, and pay directly to parents every year to express Sniff Group's gratitude to Respect and thanks to parents.",
      "健康": "Health",
      "定期员工体检，鼓励同学们锻炼健康的体魄": "Regular employee physical examinations to encourage to exercise a healthy body",
      "SNIFF非常注重员工的身心健康，健康的身体是优质工作和生活的前提保障。SNIFF鼓励同学们经常锻炼身体，塑造健康的工作生活习惯。并为全体员工提供年度员工体检，以此来保障员工的身体健康。同时还有旅游基金，年度假期等等福利，让员工有时间放松自己，陪伴家人，更好的平衡工作和生活。": "SNIFF attaches great importance to the physical and mental health of employees. A healthy body is the prerequisite for high-quality work and life. SNIFF encourages staffs to exercise regularly to keep a healthy working life living habits. And provide annual employee physical examination for all employees, in order to protect the health of employees. At the same time, there are travel funds, annual holidays and other benefits, so that employees have time to relax themselve, accompany family, and better balance work and life.",
      "开放": "Open",
      "定期语言交流学习，多国家同学碰撞思想火花": "Regular language exchange learning, many countries collide with sparks of thought",
      "SNIFF是一个国际化的公司，拥有多元的文化交流背景，日、韩、泰等多个国家地区的员工一起上班，互相交流学习，共同进步。我们一直以来坚持开放包容的态度，鼓励各国员工积极交流，互相探讨，不断开阔彼此视野。还会定期开放语言交流学习，来帮助同学们了解不同国家的文化，让多国同学们碰撞出更璀璨的思想火花。": "SNIFF is an international company with a background of diverse cultural exchanges. Employees from Japan, Korea, Thailand and other countries and regions work together, communicate and learn from each other, and make progress together. We have always adhered to an open and inclusive attitude, encouraging employees from various countries to actively communicate, discuss with each other, and constantly broaden each other's horizons. We will also regularly open up language exchange learning to help staffs understand the cultures of different countries, and let staffs from different countries collide with brighter sparks of thought.",
      "选择你喜欢的城市办公": "Choose your preferred city office",
      "杭州": "Hangzhou",
      "东京": "Tokyo",
      "大连": "Dalian",
      "广州": "Guangzhou",
      "香港": "Hong Kong",
      "韩国金浦": "Seoul-Fukuoka",
      "查看办公环境": "View office environment",
      "投递简历": "Submit your resume",
      "SNIFF集团-The直行便连续四年获得OCS中国最佳合作伙伴奖": "SNIFF Group - The Direct Bank has won the OCS China Best Partner Award for four consecutive years",
      "一个有文化的团队才称得上是团队，他们拥有共同的目标、愿景、思想、沟通方式、类似的兴趣爱好等等，这样才称得上是有生命力的团队。": "A cultured team can be called a team. They have common goals, visions, ideas, communication methods, similar hobbies, etc., so they can be called a lively team.",
      
      
      "企业愿景": "Group Vision",
      "成为世界一流的跨境电商综合系统服务商": "Be a world-class cross-border e-commerce comprehensive system service provider",
      "企业使命": "Our Mission",
      "让世界贸易变得更简单": "Make World Trade Easier",
      "帮助中国制造走出去 他国制造走进来 实现全球买 全球卖": "Help Chinese manufacturing to go out, and other countries' manufacturing to come in to realize global buying and global selling",
      "企业文化": "Company Culture",
      "感恩 、分享、助力客户、成就员工": "Gratitude, sharing, helping customers, and achieving employees",
      "企业核心能力": "Enterprise Core Competence",
      "多语种、智能化、专业性、前瞻性": "Multilingual, intelligent, professional, forward-looking",
      "企业核心价值观": "Core Values ​​Of The Company",
      "感恩分享、不断创新、成就客户、贡献社会": "Gratitude for sharing, continuous innovation, customer success, and contribution to society",
      "杭州自古以来就是我国著名的经济文化中心，流淌千年运的河，滋养出杭州独有的贸易灵魂。": "Hangzhou has been a famous economic and cultural center in my country since ancient times. The river flowing for thousands of years has nourished Hangzhou's unique trading soul.",
      "杭州也是SNIFF的起点，是一切有关“让贸易变得更简单”的愿景起航的地方。这里有SNIFF中国供应链部门、日语客户支持部门、跨境电商运营部门、平台运营部门、技术开发分公司、萧山仓储物流分公司、西子精简分公司等核心业务部门，是SNIFF的大本营。": 'Hangzhou is also the starting point of SNIFF, where all the visions of "making trade easier" set sail. There are SNIFF China supply chain department, Japanese customer support department, The core business departments such as cross-border e-commerce operation department, platform operation department, technology development branch, Xiaoshan warehousing and logistics branch, and Xizi quality inspection branch are the headquarters of SNIFF.',
      "在这座钟灵毓秀的城市里，我们工作、我们生活，我们努力，我们成长，在这里我们和SNIFF一起奋斗，书写出独一无二的贸易，与众不同的你我。SNIFF欢迎你的加入！": "In this beautiful city, we work hard, we grow up, here we struggle together with SNIFF to write a unique trade, You and me are different. SNIFF welcomes you to join us!",
      "东京分公司是Sniff的日本办公中心，员工都是更熟悉当地文化的日本籍成员，主要负责与日方各大公司进行合作洽谈，商务对接等工作。": "The Tokyo branch is the Japanese office center of Sniff. The employees are all Japanese members who are more familiar with the local culture. They are mainly responsible for cooperation negotiations and business docking with major Japanese companies.",
      "日籍员工可以更顺畅与客户进行交流和沟通，完成大客户的拜访和维护，帮助日本项目顺利推进。对于当地文化环境的了解决定来他们能掌握迅速识别和判断市场动向，掌握第一手资料。日本分公司能迅速洞察当地人们的兴趣变化，并依此来进行日本市场的宣传推广工作。欢迎加入Sniff Group，与世界各地的同事一起交流！": "Japanese employees can communicate with customers more smoothly, complete the visit and maintenance of major customers, and help Japanese projects to advance smoothly. Knowledge of the local cultural environment determines they can quickly identify and judge market trends and grasp the newlest information. The Japanese branch can quickly understand the changes of local people's interests, and use this to develop the Japanese market promotional work. Welcome to join Sniff Group and communicate with colleagues from all over the world!",
      "大连是一座依山傍海的城市，大连分公司就坐落在这样美丽城市的海边。地址位于辽宁省大连市高新园区万科创智中心C3号楼15楼1506室。": "Dalian is a city surrounded by mountains and the sea. The Dalian branch is located by the seaside of such a beautiful city. The address is located at Building 15, Building C3, Vanke Innovation and Intelligence Center, High-tech Park, Dalian City, Liaoning Province Building Room 1506.",
      "大连培养了众多优秀日语人才，因此我们将大连分公司作为我们的日语客服中心，承接客户售前&售后的客户线上问询或者电话咨询。": "Dalian has cultivated many excellent Japanese talents, so we use Dalian Branch as our Japanese customer service center to undertake online or telephone inquiries from customers before and after sales inquire.",
      "大连分公司拥有日本领导层的管理，和日本的培训专员。因此大连的日语客服更加了解日本文化，能够同理客户的需求，可以给日本客户提供日本标准的客服对应质量。": "The Dalian branch has Japanese leadership management and Japanese training specialists. Therefore, the Japanese customer service in Dalian has a better understanding of Japanese culture, can empathize with customers' needs, and can provide Japanese customers provide Japanese standard customer service corresponding quality.",
      "我们珍惜每一位客户，珍惜每一位员工，珍惜每一个来之不易的机会！所以你也珍惜这次的机会哦！": "We cherish every customer, every employee, and every opportunity!",
      "广州分公司主要负责华南地区供应商的海外供货，并提供质检，仓储，物流等服务。": "Guangzhou Branch is mainly responsible for the overseas supply of suppliers in South China, and provides services such as quality inspection, warehousing, and logistics.",
      "广州位于珠江口，毗邻香港，地理位置优越，是中国最大的对外贸易口岸之一，具有地理位置优越、交通便利、产品资源丰富等优势。": "Guangzhou is located at the mouth of the Pearl River, adjacent to Hong Kong, and has a superior geographical location. It is one of the largest foreign trade ports in China and has the advantages of superior geographical location, convenient transportation, and rich product resources.",
      "广州不仅拥有完善的海空陆交通网络，可以方便地将商品运往世界各地；同时还具备丰富的产品资源，产品种类丰富多样，能满足全球各地人们的生产生活需求。因此，广州也成为SNIFF发展跨境事业的新基地！": "Guangzhou not only has a complete sea, air and land transportation network, which can conveniently transport commodities to all parts of the world; at the same time, it also has a wealth of product resources, with a wide variety of products, which can meet the needs of the whole world. Production and living needs of people all over the world. Therefore, Guangzhou has also become a new base for SNIFF to develop cross-border business!",
      "韩国拥有良好的互联网基础设施，为电子商务的发展打下坚实基础。韩国电子商务行业市场规模位居世界前列，是重要的消费市场。": "South Korea has a good Internet infrastructure, laying a solid foundation for the development of e-commerce. The market size of South Korea's e-commerce industry ranks among the top in the world and is an important consumer market.",
      "SNIFF韩国分公司位于京畿道金浦市金浦汉江9路75号街190,7楼715号-V3(九来洞,Inner Mass汉江)，主要负责韩国地区市场开拓，招商等工作。韩国分公司是SNIFF拓展韩国市场，增加海外市场厚度的重要力量！": "SNIFF Korea Branch is located at No. 715-V3, 7th Floor, No. 190, No. 75, Hanjiang 9-ro, Gimpo, Gimpo, Gyeonggi-do (Gurae-dong, Inner Mass Hanjiang), and is mainly responsible for market development Merchants and other work in Korea. The Korean branch is an important force for SNIFF to expand the Korean market and increase the thickness of the overseas market!",
      "中国香港": "Hong Kong, China",
      "中国香港是一座高度繁荣的自由港和国际大都市，重要的国际贸易、航运中心和国际创新科技中心。": "Hong Kong, China, is a prosperous free port international metropolis, an important international trade shipping center and an international innovation and technology center.",
      "中国香港是全球少有之“自由港城”，地理位置优越，处于亚洲的中心，交通畅通，货物进出便利，在跨境电商行业的发展上具有得天独厚的优势。": 'Hong Kong, China, is one of the few "free port cities" in the world. It is strategically located in the center of Asia, with smooth transportation and convenient import and export of goods. It has unique advantages in the development of the cross-border e-commerce industry.',
      "同时香港还具有境内备货方便，外汇收款自由，税务政策便利等多种优势，是发展跨境事业最合适的港湾！": "At the same time, Hong Kong also has many advantages such as convenient products stocking,foreign exchange collection freedom, and convenient tax policies. It is the most suitable harbor for developing cross-border businesses!",
    }
  },
};

var langInPath = 'cn'

// eslint-disable-next-line no-restricted-globals
const langMatch = location.pathname.match(/\/([a-zA-Z]*)\/(.*)/)
if (!langMatch) {
  langInPath = 'cn'
} else {
  const suppertLangs = ['cn', ...Object.keys(resources)]
  if (!suppertLangs.includes(langInPath.toLowerCase())) {
    // navigator.language
    langInPath = 'cn'
  } else {
    langInPath = langMatch[1]
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: langInPath, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;