import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom'
import { useRequest } from 'ahooks';
import axios from 'axios';
import moment from 'moment';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useInViewport } from 'ahooks';

// 轮播
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import { Navigation, Autoplay } from 'swiper/modules';

// 组件
import NavMenu from '@/components/Nav1';
import FooterNav from '@/components/FooterNav'
import AboutCorp from '@/components/AboutCorp';
// 样式
import './index.scss';
import '../index.scss';
// 文件
import joinAdorn from '@/assets/line_5.png';
import newsAdorn from '@/assets/line_8.png';
import splitLine from '@/assets/splitLine.png';
// import left from '@/assets/swipe_left.png';
// import right from '@/assets/swipe_right.png';
import classNames from 'classnames';
import { useRect } from '../../components/hooks';

  // eslint-disable-next-line react-hooks/exhaustive-deps
// const SwiperButtonNext = ({ children }) => {
//   const swiper = useSwiper();
//   return (
//     <span
//       slot="container-start"
//       onClick={() => swiper.slideNext()}
//       className="swiper-button-prev"
//       style={{
//         right: "var(--swiper-navigation-sides-offset, 10px)",
//         left: "auto",
//       }}
//     >
//       <img className='swiper-btn' src={right} alt='' />
//     </span>
//   );
// };

// const SwiperButtonPrev = ({ children }) => {
//   const swiper = useSwiper();
//   return (
//     <span
//       onClick={() => swiper.slidePrev()}
//       className="swiper-button-next"
//       style={{
//         left: "var(--swiper-navigation-sides-offset, 10px)",
//         right: "auto",
//       }}
//       slot="container-start"
//     >
//       <img className='swiper-btn' src={left} alt='' />
//   </span>
//   );
// };


function getArtcles() {
  return axios({ url: `https://static-s.theckb.com/sniffGroup/article/articleList.prod.json?_time=${new Date().getTime()}`})
}

function parseSubtitleData(subtitleData) {
  const lines = subtitleData.split('\n');
  const subtitles = [];

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i].trim();
    if (line === '') continue;

    const [timeRange, text] = line.split(' ');
    const [startTime, endTime] = timeRange.split('-');

    subtitles.push({
      startTime: parseTime(startTime),
      endTime: parseTime(endTime),
      text: text,
    });
  }

  return subtitles;
}

function parseTime(timeString) {
  const [hours, minutes, seconds] = timeString.split(':');
  return (
    parseInt(hours, 10) * 3600 + parseInt(minutes, 10) * 60 + parseInt(seconds, 10)
  );
}

function getSubtitleByTime(subtitles, currentTime) {
  for (let i = 0; i < subtitles.length; i++) {
    const subtitle = subtitles[i];
    if (currentTime >= subtitle.startTime && currentTime <= subtitle.endTime) {
      return {
        subtitle: subtitle.text,
        remainingDuration: subtitle.endTime - currentTime
      };
    }
  }
  return null;
}

const subtitleData = `00:00:01-00:00:06 生产能力提高带来产量盈余，额外的生产和加工造成了资源的严重浪费
  00:00:07-00:00:11 过度浪费加速了资源的消耗，对环境也造成了巨大的伤害，我们有责任确保资源被有效利用
  00:00:12-00:00:15 合理的资源分配对减少浪费、延缓资源消耗进程至关重要
  00:00:16-00:00:35 我们立志于资源与资源的透明连接，构建全新的资源再分配系统，让资源的流动被看见`;

function Home() {
  const { t, i18n } = useTranslation();
  const { lang: langInPath } = useParams();

  const infoRef = useRef(null)
  const [infoInViewport] = useInViewport(infoRef)

  // 解析字幕数据
  const subtitlesRef = useRef(parseSubtitleData(subtitleData));

  const [articles, setArtcles] = useState([]);
  const { run } = useRequest(getArtcles, {
    onSuccess(data) {
      console.log('data:', data)
      const list = data.data.slice(0, 9)
      // .filter(({ title, enTitle }) => {
      //   if (langInPath === 'cn' && title) {
      //     return true
      //   }
      //   if (langInPath === 'en' && enTitle) {
      //     return true
      //   }
      //   return false;
      // }).slice(0, 9)
      setArtcles(list)
      console.log('list:', list)
    }
  });

  const view = useRef()
  
  function scrollToNext() {
    view.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
  }
  const infoView = useRef()

  useEffect(() => {
    const handleScroll = () => {
      const box = infoView.current;
      const boxPosition = box.getBoundingClientRect().bottom;
      const windowHeight = window.innerHeight;

      if (boxPosition < windowHeight) {
        box.classList.add('move-up');
      } else {
        box.classList.remove('move-up');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    run()
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language])

  const swiper2Ref = useRef();
  const timerId = useRef();
  const [currentSubtitle, setCurrentSubtitle] = useState('');
  const [subtitleFadein, setSubtitleFadein] = useState(true);

  function onPlay(e) {
    timerId.current = setInterval(() => {
      var res = getSubtitleByTime(subtitlesRef.current, e.target.currentTime)
      if (!res) {
        return
      }
      var { subtitle, remainingDuration } = res
      if (subtitle !== currentSubtitle) {
        setSubtitleFadein(true);
      }
      if (remainingDuration < 0.5) {
        setSubtitleFadein(false);
      }
      setCurrentSubtitle(subtitle);
    }, 500)
  }

  function onPause(e) {
    console.log('onPause')
    clearInterval(timerId.current)
  }

  function onEnded(e) {
    console.log('onEnded')
    clearInterval(timerId.current)
  }

  const [view01Rect, view01Ref] = useRect();
  const [view02Rect, view02Ref] = useRect();

  const navThemes = {
    'sniff-nav-dark': view01Rect.y > (-view01Rect.height + 64),
    'sniff-nav-light': view02Rect.y < 0 && view02Rect.y > -view02Rect.height
  }

  return (
    <>
      <div id="Top1" className="page">
        {/* 首屏 视频 */}
        <div className="video-group" ref={view01Ref}>
          <video onPlay={onPlay} onPause={onPause} onEnded={onEnded} className="video-wrap" autoPlay={true} preload="true" muted loop>
            <source autoPlay={true} preload="true" src="https://static-s.theckb.com/sniffGroup/videos/home3.mp4" type="video/mp4" muted loop />
          </video>
          <div className="screen-box">
            <NavMenu lang={langInPath} classLocation={'.video-group'} themes={navThemes} />
            <div className="content-group">
              <p className={classNames(['content-txt', 'subtitle'], { 'subtitle-fadein':  subtitleFadein, 'subtitle-fadeout': !subtitleFadein })}>{t(currentSubtitle)}</p>
              <div onClick={scrollToNext} className="slide-browse">
                <i className="iconfont slide-icon">&#xe6a9;</i>
                <i className="iconfont slide-icon1">&#xe6a9;</i>
                <span className="browse-more">{t('滑动浏览更多')}</span>
              </div>
            </div>
          </div>
        </div>
        {/* 了解直行便 */}
        <div ref={view}></div>
        <AboutCorp ref={view02Ref} />
        {/* 集团资讯 */}
        <div className="info-group"  ref={infoView}>
          <div className='info-wrap'>
            {/* <div className="info-head" > */}
            <div ref={infoRef} className={classNames(['info-head'], { 'animate': infoInViewport })}>
              <div className="head-wrap">
                <h1 className="head-txt">
                  <span>{t('进击的SNIFF「 集团资讯 」')}</span>
                  <img className="head-img"
                    src={newsAdorn}
                    alt=''
                  />
                </h1>
              </div>
              <Link to={`/${langInPath}/News#ArticleContent`}>
                <div className="btn-wrap">
                  <span className="info-btn-txt">{t('查看更多')}</span>
                  <i className="iconfont info-btn-img">&#xe6a2;</i>
                </div>
              </Link>
            </div>
            {/* <span 
              className="my-swiper-button-prev"
              onClick={() => {
                swiper2Ref.current.querySelector('.swiper-button-prev').click()
              }}>
              <img className='swiper-btn' src={left} alt='' />
            </span>
            <span
              className="my-swiper-button-next"
              onClick={() => {
                console.log(swiper2Ref.current)
                swiper2Ref.current.querySelector('.swiper-button-next').click()
              }}
            >
              <img className='swiper-btn' src={right} alt='' />
            </span> */}
            {articles.length &&
            <Swiper
              ref={swiper2Ref}
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              slidesPerView={3}
              spaceBetween={30}
              navigation={true}
              modules={[Navigation, Autoplay]}
              className="my-swiper"
            >

              {/* <SwiperButtonPrev />
              <SwiperButtonNext /> */}
              {articles.map((article)=> (
                //  style={{height: 600}}
                <SwiperSlide key={article.no}>
                  <Link to={`/${langInPath}/NewsDetails/${(article.no)}`}>
                  <div className="main-contact">
                    {/* <img className='main-img' src={article.imgUrl} alt='' /> */}
                    <img className='main-img' src={langInPath === 'en' ? article.enImgUrl : article.imgUrl} alt='' />
                    <div className="contact-desc flex-row">
                      <div className="desc-date">
                        <span className="date-year">{moment(article.date).format('YYYY')}</span>
                        <div className="desc-date-wrap justify-between">
                          <img className="split-icon" src={splitLine} alt='' />
                          <span className="date-month">{moment(article.date).format('MM')}.{moment(article.date).format('DD')}</span>
                        </div>
                      </div>
                      <div className="split-line" />
                      <span className="info-blurb">{langInPath === 'en' ? article.enTitle : article.title} </span>
                    </div>
                  </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
            }


          </div>
        </div>
        {/* 加入我们 */}
        <div className="join-group">
          <video className="video-player" autoPlay={true} preload="true" muted loop>
            <source autoPlay={true} preload="true" src="https://static-s.theckb.com/sniffGroup/videos/joinUs1.mp4" type="video/mp4" muted loop />
          </video>
          
          <div className='join-mask'>
            <div className="join-contact">
              <div className="join-wrap flex-row">
                <div className="join-title justify-between">
                  <h1 className="join-slogan">
                    <span>{t('用年轻人的方式改变世界!')}</span>
                    <img className="join-adorn" src={joinAdorn} alt=''/>
                  </h1>
                  <span className="join-txt">{t('更运动，更开放，更灵活')}</span>
                </div>
                <Link to={`/${langInPath}/JoinUs#Possibility`}>
                  <div className="join-btn">
                    <span className="join-btn-txt">{t('加入我们')}</span>
                    <i className="iconfont join-btn-icon">&#xe6a3;</i>
                  </div>
                </Link>
              </div>
            </div>
          </div>


        </div>
        {/* 底部导航 */}
        <FooterNav />
      </div>
    </>
  );
}

export default Home;
